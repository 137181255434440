import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import Home from "./components/pages/Home/Home";
import AdminDashboard from "./components/pages/Home/HomeComponents/AdminDashboard/AdminDashboard";
import { DataProvider } from "./context/DataContext";
import { TagProvider } from "./context/TagContext";
import { UsecaseProvider } from "./context/UsecaseContext";
import { ChatProvider } from "./context/ChatContext";
import { ToastContainer } from "./components/utilities/Toast";
import { UserProfileProvider } from "./context/UserProfile";
import { CatalogProvider } from "./context/CatalogContext";
import LoginProvider from "./auth/LoginProvider";
import RunningTasksSpinner from "./components/utilities/RunningTasksSpinner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default function App() {
  const [ready, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);

    if (localStorage.getItem("theme") !== "dark") return;
    document.documentElement.classList.add("dark");
  }, []);

  if (!ready) return <></>;

  return (
    <LoginProvider>
      {({ signOut, user }) => (
        <QueryClientProvider client={queryClient}>
          <UserProfileProvider>
            <DataProvider>
              <TagProvider>
                <CatalogProvider>
                  <UsecaseProvider>
                    <ChatProvider>
                      <div>
                        <ToastContainer />
                        <RunningTasksSpinner />
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/admin" element={<AdminDashboard />} />
                        </Routes>
                      </div>
                    </ChatProvider>
                  </UsecaseProvider>
                </CatalogProvider>
              </TagProvider>
            </DataProvider>
          </UserProfileProvider>
        </QueryClientProvider>
      )}
    </LoginProvider>
  );
}
