import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { sendRequest } from "../components/utilities/functions/api";
import { useUserProfile } from "../context/UserProfile";
import Auth from "../auth/AuthProvider";
import Paginator from "../components/Paginator/Paginator";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiTableAltFill } from "react-icons/ri";
import { AvailableTagsResponse, CatalogResponse } from "../types";
import { DataContext } from "../context/DataContext";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom, selectedCatalogItemsAtom } from "../atoms";
import Workers from "../utils/threading";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { backendBaseUrl } from "../utils/config";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useCatalogContext } from "../context/CatalogContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const stringToColor = (stringValue: string, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

type CatalogTableProps = {
  onEvidenceClick: (itemKey: string) => void;
  availableTags: AvailableTagsResponse["tags"];
};

const PdfViewerModal = ({
  url,
  onClose,
  search,
}: {
  url: string;
  onClose: () => void;
  search: string;
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  const [zoom, setZoom] = useState(1);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    [],
  );

  useEffect(() => {
    const handler = async () => {
      const pdfUrl = new URL(
        `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
      );
      pdfUrl.searchParams.append("pdf_url", url);
      pdfUrl.searchParams.append("search", search);
      pdfUrl.searchParams.append("just_page", "true");

      const response = await fetch(pdfUrl.href);
      setPageNumber(
        (((await response.json()) as { data: { page: number | undefined } })[
          "data"
        ]["page"] || 0) + 1,
      );
    };

    handler().catch(() => {
      setPageNumber(1);
    });
  }, [search, url]);

  const pdfUrl = new URL(
    `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
  );
  pdfUrl.searchParams.append("pdf_url", url);
  pdfUrl.searchParams.append("just_page", "false");
  pdfUrl.searchParams.append("search", search);

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-10 backdrop-blur-md overflow-hidden z-[999999]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full h-full p-4 inset-0 flex flex-col gap-4 justify-center items-center overflow-hidden">
        <div
          className={`flex h-full ${pageNumber === undefined && "bg-white"} overflow-auto`}
        >
          <Document file={pdfUrl.href} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={zoom} />
          </Document>
        </div>
        <div className="h-10 shrink-0 flex gap-2">
          <button
            className="bg-secondary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) - 1 > 0 ? pageNumber - 1 : pageNumber,
              );
            }}
          >
            prev
          </button>
          <button
            className="bg-primary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (!numPages) return;
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) + 1 < numPages ? pageNumber + 1 : numPages,
              );
            }}
          >
            next
          </button>
          <button
            onClick={onClose}
            className="bg-rose-400 px-4 py-2 text-white rounded-md"
          >
            Close
          </button>
          <button
            onClick={() => setZoom(zoom + 0.1)}
            className="bg-blue-500 ml-20 px-4 py-2 text-white rounded-md"
          >
            +
          </button>

          <button
            onClick={() => setZoom(zoom - 0.1)}
            className="bg-blue-500 px-4 py-2 text-white rounded-md"
          >
            -
          </button>
          <div className="bg-white text-sm rounded-md flex items-center p-2">
            page {pageNumber} of {numPages}
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvancedTagFiltersModal = ({
  currentFilters,
  onCancel,
  onAccept,
}: {
  currentFilters: string[];
  onCancel: () => void;
  onAccept: (newFilters: string[]) => void;
}) => {
  const [newFilters, setNewFilters] = useState(new Set(currentFilters));
  const [search, setSearch] = useState("");
  const { availableTags, preferences, ruleDict } = useContext(DataContext);

  return (
    <div className="w-full p-4 flex flex-col overflow-hidden">
      <header className="text-lg text-buttonGrey font-bold">
        Available tags to filter your data
      </header>
      <input
        type="text"
        placeholder="Search Tag"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className="w-full rounded-md px-4 py-2 mt-4 outline-none border mb-1"
      />
      <div className="w-full overflow-auto">
        <table className="w-full">
          <tbody>
            {availableTags &&
              availableTags.llm.tagger_params.tag_dict &&
              Object.keys({
                ...availableTags.llm.tagger_params.tag_dict,
                ...ruleDict,
              })
                .filter((key) => {
                  return search
                    ? key.toLowerCase().includes(search.toLowerCase())
                    : key !== "file_directory" &&
                        !Object.keys(
                          preferences.system.SENSITIVITY_TAGS,
                        ).includes(key) &&
                        !preferences.system.EXCLUDE_TAGS.includes(key);
                })
                .map((tagKey, index) => {
                  return (
                    <tr
                      key={`filter-tag-${tagKey}`}
                      className={index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}
                    >
                      <td className="text-md p-2">{tagKey}</td>
                      <td>
                        <div className="flex p-2 gap-1 flex-wrap">
                          <input
                            type="checkbox"
                            className="p-3"
                            checked={newFilters.has(tagKey)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                newFilters.add(tagKey);
                              } else {
                                newFilters.delete(tagKey);
                              }
                              setNewFilters(new Set(newFilters));
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <div className="flex mt-2 gap-2 w-full">
        <button
          onClick={() => {
            onAccept([...newFilters]);
          }}
          className="text-sm w-full text-center bg-primary text-white rounded-md shadow-md px-4 py-2"
        >
          Apply
        </button>
        <button
          onClick={onCancel}
          className="text-sm w-full text-center bg-white text-primary border-2 border-primary rounded-md shadow-md px-4 py-2"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const CatalogTable = ({
  onEvidenceClick,
  availableTags,
}: CatalogTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const userProfile = useUserProfile();
  const [actionsFile, setActionsFile] = useState("");
  const [expandedFiles, setExpandedFiles] = useState(new Set<string>());
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [isGloballyExpanded, setIsGloballyExpanded] = useState(false);
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );

  const {
    setShowAdvancedTagFilters,
    showAdvancedTagFilters,
    taggingFilters,
    setTaggingFilters,
  } = useCatalogContext();

  const [advancedTaggingFilters, setAdvancedTaggingFilters] = useState(
    {} as Record<string, string[]>,
  );
  const [tagFilterOpened, setTagFilterOpened] = useState("");
  const [tagFilterOpenedSearch, setTagFilterOpenedSearch] = useState("");
  const {
    hiddenCategories,
    ruleDict,
    handleDatasetDelete,
    catalogFiles,
    currentDataGroup,
    setSelectedFilters,
    usedCatalog,
  }: {
    currentDataGroup: CatalogResponse["catalog"];
    hiddenCategories: any;
    ruleDict: any;
    handleDatasetDelete: any;
    catalogFiles: any;
    setSelectedFilters: any;
    usedCatalog: string;
  } = useContext(DataContext);
  useEffect(() => {
    setSelectedFilters(taggingFilters);
  }, [setSelectedFilters, taggingFilters]);

  const sortedEntries = useMemo(() => {
    return Object.entries(currentDataGroup || {})
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .sort(([_, fileA], [__, fileB]) => {
        if (!fileA.last_processed_at) {
          fileA.last_processed_at = new Date("1976/01/01").toISOString();
        }
        if (!fileB.last_processed_at) {
          fileB.last_processed_at = new Date("1976/01/01").toISOString();
        }
        return (
          new Date(fileB.last_processed_at).getTime() -
          new Date(fileA.last_processed_at).getTime()
        );
      });
  }, [currentDataGroup, currentPage, pageSize]);

  useEffect(() => {
    (async () => {
      const advancedTaggingFilters = await Workers.invoke(
        "getAdvancedTaggingFilters",
        catalogFiles,
      );
      setAdvancedTaggingFilters(advancedTaggingFilters);
    })();
  }, [catalogFiles]);

  return (
    <div className="flex flex-col w-full h-full items-center overflow-hidden">
      {pdf && (
        <PdfViewerModal
          url={pdf}
          onClose={() => setPdf("")}
          search={pdfSearch}
        />
      )}
      <div className="w-full flex p-2 bg-white gap-2">
        {Object.keys(taggingFilters).length !== 0 && (
          <div className="w-full flex gap-1 ml-4 items-center">
            {Object.entries(taggingFilters).map(([tag, value]) => (
              <div
                className="relative whitespace-nowrap select-none flex bg-gray-50 rounded-md px-8 py-2 border cursor-pointer"
                onClick={() => {
                  setTagFilterOpened(tag);
                  setTagFilterOpenedSearch("");
                }}
              >
                {tag} {`(${taggingFilters?.[tag]?.size || 0})`}
                {tagFilterOpened === tag && (
                  <>
                    <div className="text-zinc-600 absolute top-[calc(100%_+_4px)] left-0 z-[100] min-w-[200px] max-w-[300px] overflow-hidden bg-white border rounded-md">
                      <div>
                        <input
                          type="text"
                          className="w-full bg-white px-4 py-2 outline-none"
                          placeholder="Search Value"
                          value={tagFilterOpenedSearch}
                          onChange={(e) => {
                            setTagFilterOpenedSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div className="max-h-[200px] overflow-auto w-full">
                        {advancedTaggingFilters &&
                          advancedTaggingFilters.hasOwnProperty(tag) &&
                          advancedTaggingFilters[tag]
                            .filter((value) => {
                              if (tagFilterOpenedSearch) {
                                return value
                                  .toLowerCase()
                                  .includes(
                                    tagFilterOpenedSearch.toLowerCase(),
                                  );
                              }
                              return true;
                            })
                            .map((value, index) => {
                              const isTagValueSelected =
                                taggingFilters[tag]?.has(value);
                              return (
                                <div
                                  className={`px-4 py-2 ${isTagValueSelected ? "bg-slate-600 text-white" : index % 2 === 0 ? "bg-slate-50 hover:bg-slate-100" : "bg-white hover:bg-slate-100"}`}
                                  onClick={() => {
                                    taggingFilters[tag] =
                                      taggingFilters[tag] || new Set();
                                    if (isTagValueSelected) {
                                      taggingFilters[tag].delete(value);
                                    } else {
                                      taggingFilters[tag].add(value);
                                    }
                                    setTaggingFilters({ ...taggingFilters });
                                  }}
                                >
                                  {value}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div
                      className="fixed z-[99] inset-0 cursor-default"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagFilterOpened("");
                        setTagFilterOpenedSearch("");
                      }}
                    ></div>
                  </>
                )}
                <div className=" absolute top-1 right-2">
                  <button
                    className="font-semibold text-lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTaggingFilters((prev) => {
                        delete prev[tag];
                        return { ...prev };
                      });
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
            ))}
            <button
              disabled={Object.values(taggingFilters).flat().length === 0}
              className="border-2 border-primary py-2 px-4 text-primary rounded-md whitespace-nowrap"
              onClick={() => {
                setTaggingFilters({});
              }}
            >
              Clear all
            </button>
          </div>
        )}
      </div>
      {showAdvancedTagFilters && (
        <div className="absolute z-50 inset-0 p-4 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center">
          <div className="w-full max-w-lg max-h-[50vh] p-4 bg-white rounded-md shadow-md flex flex-col gap-4 overflow-hidden">
            <AdvancedTagFiltersModal
              onCancel={() => setShowAdvancedTagFilters(false)}
              onAccept={(newFilters) => {
                setTaggingFilters((prev) => ({
                  ...prev,
                  ...Object.fromEntries(
                    newFilters.map((filter) => [filter, new Set<string>([])]),
                  ),
                }));
                setShowAdvancedTagFilters(false);
              }}
              currentFilters={Object.keys(taggingFilters)}
            />
          </div>
        </div>
      )}

      <div className="w-full h-full bg-white overflow-auto relative">
        {!currentDataGroup && (
          <div className="absolute inset-0 bg-white flex justify-center items-center">
            <div className="text-gray-200 animate-pulse text-6xl">
              <RiTableAltFill />
            </div>
          </div>
        )}
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="text-xs uppercase">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 w-10 top-0 sticky bg-gray-100 z-40"
              >
                <input
                  type="checkbox"
                  className="mt-0.5"
                  checked={
                    selectedCatalogItems.size ===
                    Object.keys(currentDataGroup || {}).length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCatalogItems(
                        new Set(Object.keys(currentDataGroup || {})),
                      );
                    } else {
                      setSelectedCatalogItems(new Set());
                    }
                  }}
                />
              </th>
              <th
                scope="col"
                className="px-6 p-3 top-0 sticky bg-gray-100 w-60 z-40"
              >
                File name
              </th>
              <th
                scope="col"
                className="text-left px-6 py-3 top-0 sticky bg-gray-100 z-40"
              >
                <div className="flex gap-1">
                  <div
                    className={`text-xl h-6 flex items-center justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                    onClick={() => {
                      setIsGloballyExpanded((state) => !state);
                    }}
                  >
                    {isGloballyExpanded ? (
                      <FaMinimize />
                    ) : (
                      <FaExpandArrowsAlt />
                    )}
                  </div>
                  <div>Tags</div>
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-40"
              >
                Actions
              </th>
              <th
                scope="col"
                className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-40"
              ></th>
              <th className="text-left w-24 px-6 py-3 top-0 sticky bg-gray-100 z-40">
                Evidence
              </th>
            </tr>
          </thead>
          <tbody className="">
            {sortedEntries.map(([docKey, tags], index) => {
              const isExpanded =
                isGloballyExpanded || expandedFiles.has(docKey);
              const isActionsOpened = docKey === actionsFile;
              return (
                <tr className="bg-white border-b" key={`${docKey}-${index}`}>
                  <td className="w-1">
                    <div className="justify-center items-center flex">
                      <input
                        type="checkbox"
                        checked={selectedCatalogItems.has(docKey)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            selectedCatalogItems.add(docKey);
                          } else {
                            selectedCatalogItems.delete(docKey);
                          }
                          setSelectedCatalogItems(
                            new Set(selectedCatalogItems),
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm break-all text-gray-700">
                    <div className="w-60">{docKey}</div>
                  </td>
                  <td className="px-6 py-4 flex gap-1">
                    <div
                      className={`flex gap-1 transition-all flex-wrap ${isExpanded ? "max-h-none overflow-auto" : "max-h-6 overflow-hidden"}`}
                    >
                      {tags &&
                        Object.entries(tags)
                          .filter(([key, value]) => {
                            return (
                              key !== "chunks" &&
                              key !== "file_directory" &&
                              !hiddenCategories?.includes(key) &&
                              value &&
                              Array.isArray(value) &&
                              value[0] &&
                              key in
                                availableTags.sensitivity.tagger_params
                                  .tag_dict ===
                                false &&
                              Object.keys(
                                {
                                  ...availableTags.llm.tagger_params.tag_dict,
                                  ...ruleDict,
                                } || {},
                              ).includes(key)
                            );
                          })
                          .map(([tagKey, value], index) => {
                            let processedValue;

                            if (Array.isArray(value)) {
                              // value being passed here is an array with a single element
                              if (
                                typeof value[0] === "string" &&
                                value[0].startsWith("[") &&
                                value[0].endsWith("]")
                              ) {
                                try {
                                  const correctedString = value[0]
                                    .replace(/'/g, '"')
                                    .replace(/(\w)"(\w)/g, "$1'$2"); // revert double quotes back to single quotes if they occur within words
                                  processedValue = JSON.parse(correctedString);
                                } catch (error) {
                                  console.error(
                                    "Error parsing the array-like string:",
                                    error,
                                    "Original value:",
                                    value[0],
                                  );
                                  processedValue = [value[0]];
                                }
                              } else {
                                processedValue = [value[0]];
                              }
                            } else if (typeof value === "string") {
                              const trimmedValue = value.trim();
                              if (
                                trimmedValue.startsWith("[") &&
                                trimmedValue.endsWith("]")
                              ) {
                                try {
                                  processedValue = JSON.parse(trimmedValue); // parse a list-like string
                                } catch (error) {
                                  console.error("Error parsing value:", error);
                                  processedValue = [trimmedValue]; // fallback to original string if parsing fails
                                }
                              }
                            }
                            return processedValue.map(
                              (val: string, idx: number) => (
                                <div
                                  className="text-xs py-1 px-2 rounded-md"
                                  key={`${docKey}-${tagKey}-${val}-${idx}`}
                                  style={{
                                    backgroundColor:
                                      stringToColor(tagKey, "40") ||
                                      "#80808028",
                                    cursor:
                                      docKey.includes(".pdf") ||
                                      docKey.includes(".docx")
                                        ? "pointer"
                                        : "auto",
                                  }}
                                  onClick={async () => {
                                    if (
                                      !docKey.includes(".pdf") &&
                                      !docKey.includes(".docx")
                                    )
                                      return;
                                    if (!currentDataGroup) return;
                                    const file = currentDataGroup[docKey];
                                    const fileDirectory =
                                      file["file_directory"][0];
                                    const storageName = file["storage_name"][0];
                                    const storageType = file["storage_type"][0];
                                    const response = await sendRequest(
                                      {
                                        [(userProfile as any).system
                                          .API_USERNAME_KEYWORD]: (
                                          await Auth.currentAuthenticatedUser()
                                        ).username,
                                      },
                                      `/api/catalog/download?file_directory=${fileDirectory}&storage_type=${storageType}&storage_name=${storageName}&file_name=${docKey}`,
                                      "GET",
                                    );
                                    if (
                                      !response ||
                                      typeof response === "string"
                                    )
                                      return;

                                    const { data } = await response.json();
                                    if (
                                      (data.url as string).includes(".pdf?") ||
                                      (data.url as string).includes(".docx?")
                                    ) {
                                      setPdf(data.url);
                                      const searchEvidence = Object.values(
                                        tags["chunks"],
                                      )
                                        .filter((chunk) => chunk[tagKey])
                                        .map((chunk) => chunk[tagKey].evidence);
                                      setPdfSearch(
                                        JSON.stringify(
                                          [...new Set(searchEvidence)].filter(
                                            Boolean,
                                          ),
                                        ),
                                      );
                                    } else {
                                      window.open(data.url, "_blank");
                                      setActionsFile("");
                                    }
                                  }}
                                >
                                  <span className="font-bold text-black">
                                    {tagKey}:
                                  </span>{" "}
                                  {val}
                                </div>
                              ),
                            );
                          })
                          .flat()}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center relative">
                      <BsThreeDotsVertical
                        className="cursor-pointer"
                        onClick={() => {
                          setActionsFile(isActionsOpened ? "" : docKey);
                        }}
                      />
                      {isActionsOpened && (
                        <div className="absolute z-30 select-none top-[calc(100%_+_8px)] w-40 bg-white border rounded-md shadow-md flex flex-col">
                          <div
                            className="fixed inset-0 z-20"
                            onClick={() => setActionsFile("")}
                          ></div>
                          <div
                            className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                            onClick={async () => {
                              if (!currentDataGroup) return;
                              const file = currentDataGroup[docKey];
                              const fileDirectory = file["file_directory"][0];
                              const storageName = file["storage_name"][0];
                              const storageType = file["storage_type"][0];
                              const response = await sendRequest(
                                {
                                  [(userProfile as any).system
                                    .API_USERNAME_KEYWORD]: (
                                    await Auth.currentAuthenticatedUser()
                                  ).username,
                                },
                                `/api/catalog/download?file_directory=${fileDirectory}&storage_type=${storageType}&storage_name=${storageName}&file_name=${docKey}`,
                                "GET",
                              );
                              if (!response || typeof response === "string")
                                return;

                              const { data } = await response.json();
                              if (
                                (data.url as string).includes(".pdf?") ||
                                (data.url as string).includes(".docx?")
                              ) {
                                setPdf(data.url);
                              } else {
                                window.open(data.url, "_blank");
                                setActionsFile("");
                              }
                            }}
                          >
                            Open
                          </div>
                          <div
                            className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                            onClick={async () => {
                              if (!currentDataGroup) return;
                              const file = currentDataGroup[docKey];
                              const fileDirectory = file["file_directory"][0];
                              const storageName = file["storage_name"][0];
                              const storageType = file["storage_type"][0];
                              const response = await sendRequest(
                                {
                                  [(userProfile as any).system
                                    .API_USERNAME_KEYWORD]: (
                                    await Auth.currentAuthenticatedUser()
                                  ).username,
                                },
                                `/api/catalog/download?file_directory=${fileDirectory}&storage_type=${storageType}&storage_name=${storageName}&file_name=${docKey}`,
                                "GET",
                              );
                              if (!response || typeof response === "string")
                                return;

                              const { data } = await response.json();
                              window.open(data.url, "_blank");
                              setActionsFile("");
                            }}
                          >
                            Download
                          </div>
                          <div
                            className="cursor-pointer  z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                            onClick={() => {
                              setActionsFile("");
                              handleDatasetDelete(docKey);
                            }}
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <div
                    className={`min-h-[10vh] flex justify-center align-bottom items-center text-xl transition-all cursor-pointer rounded-md  ${isExpanded ? "text-grey" : "text-grey"}`}
                    onClick={() => {
                      if (isExpanded) {
                        expandedFiles.delete(docKey);
                      } else {
                        expandedFiles.add(docKey);
                      }
                      setExpandedFiles(new Set(expandedFiles));
                    }}
                  >
                    {isExpanded ? <FaMinimize /> : <FaExpandArrowsAlt />}
                  </div>
                  <td className="px-6 py-4">
                    <button
                      className="px-4 py-2 text-primary border-2 border-primary  rounded-md whitespace-nowrap"
                      onClick={() => onEvidenceClick(docKey)}
                    >
                      See evidence
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="bg-white shadow-md w-full  rounded-b-md">
        <Paginator
          onNewPage={(newPage) => setCurrentPage(newPage)}
          currentPage={currentPage}
          onNewPageSize={(newPageSize) => {
            setCurrentPage(1);
            setPageSize(newPageSize);
          }}
          pageSize={pageSize}
          numberOfItems={Object.keys(currentDataGroup || {}).length}
        />
      </div>
    </div>
  );
};

export default CatalogTable;
