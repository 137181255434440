import React from "react";
import DataFilterModal from "../../../../../../../../utilities/DataFilterModal/DataFilterModal";
import FilterTableDataList from "../../../../../../../../utilities/FilterTableList/FilterTableList";
import "./DataFilterComponent.css";
export default function DataFilterComponent(props) {
  return (
    <div className="DataFilterComponent">
      <DataFilterModal
        isOpen={props.isModalOpen}
        onClose={() => props.setIsModalOpen(false)}
      >
        <FilterTableDataList
          currentDataGroup={props.catalogFiles}
          labels={props.labels}
          catalogSummary={props.catalogSummary}
          addedData={props.addedData}
          setAddedData={props.setAddedData}
          confirmUsecaseData={props.confirmUsecaseData}
          currentUseCase={props.currentUseCase}
          showScreen={props.showScreen}
          onClose={() => props.setIsModalOpen(false)}
        />
      </DataFilterModal>
    </div>
  );
}
