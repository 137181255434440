import React, { useEffect, useState } from "react";
import { format, startOfMonth } from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  TokenUsageTracking,
  useTokenUsage,
} from "../../../../../../api/queryHooks";
import { toast } from "../../../../../utilities/Toast";
import { CircularProgress } from "@mui/material";
import { formatDateInputValue } from "../../../../../../utils/datetime";
import { ChartDataEntry, GraphProps } from "./types";
import { NoDataComponent } from "./NoDataComponent";
import { TokenUsageGraph } from "./TokenUsageGraph";
import { tokenListToChartData } from "./utils";

export function TokenUsageComponent() {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [tokenUsageData, setTokenUsageData] = useState<TokenUsageTracking>();
  const [chartData, setChartData] = useState<ChartDataEntry[]>();

  const { isLoading, data, error } = useTokenUsage({
    startDate,
    endDate,
  });

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    if (error) {
      toast.error({
        title: "Failed to fetch token usage data:",
        description: error,
      });
      return;
    }

    setTokenUsageData(data);
    const transformedData = transformDataForGraph(data);
    setChartData(transformedData);
  }, [isLoading, data, error]);

  const transformDataForGraph = (
    apiResponse: TokenUsageTracking,
  ): ChartDataEntry[] => {
    return tokenListToChartData(apiResponse.token_list.token_list);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedDate = new Date(event.target.value);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setEndDate(selectedDate);
  };

  const CostUsageGraph: React.FC<GraphProps> = ({ data }) => {
    if (!data || data.length === 0) {
      return <NoDataComponent />;
    }

    const dollarFormatter = (value: any) => {
      let numberValue = Number(value);

      if (Number.isFinite(numberValue)) {
        return `$${numberValue.toFixed(2)}`;
      } else {
        console.error(
          "Expected a number or a numeric string, but received:",
          value,
        );
        return `$0.00`;
      }
    };

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tickFormatter={dollarFormatter} />
          <Tooltip formatter={(value: any) => dollarFormatter(value)} />
          <Legend />
          <Bar dataKey="totalCost" fill="#82ca9d" name="Token Cost" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="p-3">
      <div className="flex flex-col md:flex-row justify-center items-center gap-6 mt-4">
        <div>
          <label className="block text-gray-800 font-medium mb-1">Start:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-green-500 rounded-lg shadow-lg focus:border-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(startDate)}
            onChange={handleStartDateChange}
            max={formatDateInputValue(endDate)}
          />
        </div>
        <div>
          <label className="block text-gray-800 font-medium mb-1">End:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-green-500 rounded-lg shadow-lg focus:border-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(endDate)}
            onChange={handleEndDateChange}
            min={formatDateInputValue(startDate)}
            max={formatDateInputValue(new Date())}
          />
        </div>
      </div>
      {isLoading && (
        <div className="text-gray-500 text-lg text-center py-10">
          <CircularProgress />
        </div>
      )}
      {!isLoading && chartData && (
        <div className="space-y-6">
          <div className="shadow-lg rounded-lg overflow-hidden">
            <TokenUsageGraph data={chartData} />
          </div>
          <div className="shadow-lg rounded-lg overflow-hidden">
            <CostUsageGraph data={chartData} />
          </div>
          {tokenUsageData && (
            <div className="bg-white p-4 shadow-lg rounded-lg">
              <div className="text-center space-y-2">
                <span className="text-green-600 text-xl font-semibold">
                  Total Spend: $
                  {tokenUsageData.token_list.total_cost.toLocaleString()}
                </span>
                <br />
                <span className="text-gray-700 text-lg">
                  Token Usage:{" "}
                  {Number(
                    tokenUsageData.token_list.total_token_cost,
                  ).toLocaleString()}{" "}
                  Tokens
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
