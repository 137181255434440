import { atom } from "jotai";

export const darkModeAtom = atom(localStorage.getItem("theme") === "dark");
export const runningTasksAtom = atom<
  {
    id: string;
    process: string;
    description: string;
    completed: number;
  }[]
>([]);
export const documentsUploadTaskAtom = atom<string | null>(null);
export const selectedCatalogItemsAtom = atom(new Set<string>());
export const pdfAtom = atom("");
export const pdfSearchAtom = atom("");
export const pdfInitialPageAtom = atom(1);
export const selectedTagKeysAtom = atom([]);
