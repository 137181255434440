import { ResponsiveContainer } from "recharts";

export const NoDataComponent: React.FC = () => (
  <ResponsiveContainer width="100%" height="100%">
    <svg>
      <rect width="100%" height="100%" fill="#f9f9f9" />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        fill="#a9a9a9"
        fontSize="16"
      >
        No Data Available
      </text>
      <text
        x="50%"
        y="50%"
        dy="20"
        textAnchor="middle"
        dominantBaseline="central"
        fill="#a9a9a9"
        fontSize="12"
      >
        Please select a different date range.
      </text>
    </svg>
  </ResponsiveContainer>
);
