import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import RunningTasksModal from "../RunningTasksModal";
import { runningTasksAtom } from "../../../atoms";
import { useAtom } from "jotai";

const RunningTasksSpinner = () => {
  const [runningTasks] = useAtom(runningTasksAtom);
  const [showRunningTasksModal, setShowRunningTasksModal] = useState(false);

  if (runningTasks.filter((task) => task.completed !== 1).length === 0)
    return null;

  return (
    <>
      <div className="fixed p-4 z-50 right-0 bottom-0">
        <button
          type="button"
          title="View Running Tasks"
          className="bg-[#369e8c] text-white py-4 px-4 rounded-full flex items-center"
          onClick={() => {
            setShowRunningTasksModal(true);
          }}
        >
          <FaSpinner className={`animate-spin h-10 w-10`} />
        </button>
      </div>
      {showRunningTasksModal && (
        <div className="Modal z-50 backdrop-blur-md">
          <div className="ModalContent">
            <span
              className="Close"
              onClick={() => {
                setShowRunningTasksModal(false);
              }}
            >
              &times;
            </span>
            <RunningTasksModal />
          </div>
        </div>
      )}
    </>
  );
};

export default RunningTasksSpinner;
