import { useContext } from "react";
import DefineUseCaseSection from "./UseCaseLibraryComponents/DefineUseCaseSection/DefineUseCaseSection";
import "./UseCaseLibrary.css";
import UseCaseLibraryMenu from "./UseCaseLibraryMenu";
import { UsecaseContext } from "../../../../../context/UsecaseContext";

function UseCaseLibrary() {
  const { usecaseStage, setUsecaseStage } = useContext(UsecaseContext);

  const { currentUseCase } = useContext(UsecaseContext);

  return (
    <div className="flex flex-col h-full justify-betweenbox-border w-full">
      <div className="flex-grow bg-white dark:bg-zinc-800 rounded-md">
        {usecaseStage === "menu" && currentUseCase && <UseCaseLibraryMenu />}
        {usecaseStage === "usecase-definition" && <DefineUseCaseSection />}
      </div>
      {usecaseStage !== "menu" && usecaseStage !== "usecase-definition" && (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <button
            className="px-4 py-2 rounded ButtonBasics bg-primary text-white"
            onClick={() => setUsecaseStage("menu")}
            style={{
              marginLeft: "1vw",
              marginBottom: "1vw",
            }}
          >
            Previous
          </button>
        </div>
      )}
    </div>
  );
}

export default UseCaseLibrary;
