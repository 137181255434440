import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { NoDataComponent } from "./NoDataComponent";
import { GraphProps } from "./types";

export const CostUsageGraph: React.FC<GraphProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return <NoDataComponent />;
  }

  const dollarFormatter = (value: any) => {
    let numberValue = Number(value);

    if (Number.isFinite(numberValue)) {
      return `$${numberValue.toFixed(2)}`;
    } else {
      console.error(
        "Expected a number or a numeric string, but received:",
        value,
      );
      return `$0.00`;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={dollarFormatter} />
        <Tooltip formatter={(value: any) => dollarFormatter(value)} />
        <Legend />
        <Bar dataKey="totalCost" fill="#82ca9d" name="Token Cost" />
      </BarChart>
    </ResponsiveContainer>
  );
};
