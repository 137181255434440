import { ChartDataEntry, TokenEntry } from "./types";

export const tokenListToChartData = (
  tokenList: TokenEntry[],
): ChartDataEntry[] => {
  const result = tokenList.reduce(
    (groupedByDate, entry) => {
      if (!groupedByDate[entry.date]) {
        groupedByDate[entry.date] = {
          date: entry.date,
          models: [],
          totalCost: 0,
          promptTokens: 0,
          completionTokens: 0,
          totalTokens: 0,
        };
      }

      entry.model_costs.forEach((model) => {
        groupedByDate[entry.date].models.push(model);
        groupedByDate[entry.date].totalCost += Number(model.cost || 0);
        groupedByDate[entry.date].promptTokens += Number(model.prompt_tokens);
        groupedByDate[entry.date].completionTokens += Number(
          model.completion_tokens,
        );
        groupedByDate[entry.date].totalTokens += Number(model.total_tokens);
      });

      return groupedByDate;
    },
    {} as { [key: string]: ChartDataEntry },
  );

  return Object.values(result);
};
