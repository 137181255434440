import { TableCell } from "@aws-amplify/ui-react";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  CircularProgress,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import {
  EvidenceReportEntry,
  useEvidenceReports,
} from "../../../api/queryHooks";
import "./styles.css";

type Props = {
  onClose: () => void;
};

export const EvidenceReport: FC<Props> = ({ onClose }) => {
  const { isLoading, data = [] } = useEvidenceReports();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const groupedByTagname: [string, number][] = useMemo(() => {
    const grouped: [string, number][] = Object.entries(
      data.reduce(
        (acc: Record<string, number>, entry: EvidenceReportEntry) => {
          if (!acc[entry.tagname]) {
            acc[entry.tagname] = 0;
          }
          acc[entry.tagname] += 1;
          return acc;
        },
        {} as Record<string, number>,
      ),
    );

    return grouped.sort((a, b) => b[1] - a[1]);
  }, [data]);

  if (isLoading) {
    return (
      <Modal open onClose={onClose}>
        <Box className="EvidenceReportsModal LoadingIndicator">
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose}>
      <Box className="EvidenceReportsModal">
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "1rem",
          }}
        >
          Evidence Report{" "}
          <Chip variant="filled" color="info" label={data.length} />
        </Typography>
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTabIndex: number) => setActiveTabIndex(newTabIndex)}
        >
          <Tab label="All" />
          <Tab label="Grouped by Tagname" />
        </Tabs>
        {activeTabIndex === 0 && (
          <TableContainer className="EvidenceReports">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Tagname</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Catalogname</TableCell>
                  <TableCell>Catalog Team</TableCell>
                  <TableCell>Is Valid</TableCell>
                  <TableCell>Filename</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((entry: EvidenceReportEntry, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      {new Date(entry.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>{entry.tagname}</TableCell>
                    <TableCell>{entry.email}</TableCell>
                    <TableCell>{entry.catalogname}</TableCell>
                    <TableCell>{entry.catalog_team}</TableCell>
                    <TableCell className="centered">
                      {entry.is_valid ? (
                        <FontAwesomeIcon icon={faCheck} color="green" />
                      ) : (
                        <FontAwesomeIcon icon={faX} color="red" />
                      )}
                    </TableCell>
                    <TableCell>{entry.filename}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {activeTabIndex === 1 && (
          <TableContainer className="EvidenceReports">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Tagname</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedByTagname.map(([tagname, count], index) => (
                  <TableRow key={index}>
                    <TableCell>{tagname}</TableCell>
                    <TableCell>{count}</TableCell>
                    <TableCell>
                      {(count / data.length).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "percent",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};
