import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { useUserProfile } from "../../../../../../context/UserProfile";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import DynamicTable from "./DynamicTableComponent";

type ProfileComponentProps = {
  activeTab: string;
  currentFormValues: Record<string, any>;
  setCurrentFormValues: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >;
};

export function ProfileComponent({
  activeTab,
  currentFormValues,
  setCurrentFormValues,
}: ProfileComponentProps) {
  const { preferences, setPreferences, availableTags } =
    useContext(DataContext);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const RISK_LEVEL_OPTIONS = ["High", "Medium", "Low"];
  const VECTOR_DB_OPTIONS = ["local", "postgres", "qdrant"];
  const userProfile = useUserProfile();

  useEffect(() => {
    if (userProfile) {
      setCurrentFormValues(
        userProfile as {
          profile: Record<string, string>;
          webapp_profile: Record<string, string>;
          hidden_tags: Record<string, string>;
        },
      );
      const [parsedValue, _] = safeParseJson(
        (userProfile as { hidden_tags: Record<string, string> }).hidden_tags
          .HIDDEN_TAGS,
      );
      setSelectedTags(parsedValue || []);
    }
  }, [userProfile, setCurrentFormValues]);

  const handleChange = (path: string, newValue: any) => {
    setCurrentFormValues((prevValues) => {
      const newValues = { ...prevValues };
      const pathKeys = path.split(".");
      let lastObj = newValues;
      for (let i = 0; i < pathKeys.length - 1; i++) {
        const key = pathKeys[i];
        if (
          !lastObj[key] ||
          typeof lastObj[key] !== "object" ||
          Array.isArray(lastObj[key])
        ) {
          lastObj[key] = {};
        }
        lastObj = lastObj[key];
      }
      lastObj[pathKeys[pathKeys.length - 1]] = newValue;
      return newValues;
    });
  };

  const handleChangeWrapper = (path: string, newValue: any) => {
    handleChange(`${activeTab}.${path}`, newValue);
  };

  const safeParseJson = (str: string): [any, boolean] => {
    try {
      const parsed = JSON.parse(str);
      return [parsed, true];
    } catch (e) {
      return [str, false];
    }
  };

  const ConfigurationInput = ({
    label,
    type,
    value,
  }: {
    label: string;
    type: string;
    value: any;
  }) => {
    const [inputValue, setInputValue] = useState(value);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
      handleChange(`${activeTab}.${label}`, inputValue);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1">
          {label}
          <input
            type={type}
            value={inputValue ?? ""}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 text-base focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </label>
      </div>
    );
  };

  const ConfigurationSelect = ({
    label,
    value,
    options,
  }: {
    label: string;
    value: any;
    options: string[];
  }) => (
    <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
      <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1">
        {label}
        <select
          value={value || ""}
          onChange={(e) =>
            handleChange(`${activeTab}.${label}`, e.target.value)
          }
          className="form-select mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 text-base focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  );

  const ConfigurationCheckbox = ({
    label,
    checked,
  }: {
    label: string;
    checked: boolean;
  }) => (
    <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
      <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1 flex items-center gap-2">
        {label}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) =>
            handleChange(`${activeTab}.${label}`, e.target.checked)
          }
          className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 focus:ring-1 focus:ring-blue-500 rounded"
        />
      </label>
    </div>
  );

  const renderRateLimits = (rateLimits: Record<string, any>): JSX.Element => {
    const rateLimitsPath = "rate_limits";

    return (
      <div className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
        <label
          htmlFor={rateLimitsPath}
          className="text-gray-700 dark:text-gray-300 text-m font-medium"
        >
          Rate Limits
        </label>
        <div className="space-y-4">
          <div>
            <label
              htmlFor={`${rateLimitsPath}.TOKENS_PER_MINUTE`}
              className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1"
            >
              Tokens per Minute
            </label>
            <input
              type="number"
              id={`${rateLimitsPath}.TOKENS_PER_MINUTE`}
              value={rateLimits.TOKENS_PER_MINUTE || ""}
              onChange={(e) =>
                handleChange(
                  `${rateLimitsPath}.TOKENS_PER_MINUTE`,
                  parseInt(e.target.value, 10),
                )
              }
              className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 text-base focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div>
            <label
              htmlFor={`${rateLimitsPath}.REQUESTS_PER_MINUTE`}
              className="block text-gray-700 dark:text-gray-300 text-sm font-medium mb-1"
            >
              Requests per Minute
            </label>
            <input
              type="number"
              id={`${rateLimitsPath}.REQUESTS_PER_MINUTE`}
              value={rateLimits.REQUESTS_PER_MINUTE || ""}
              onChange={(e) =>
                handleChange(
                  `${rateLimitsPath}.REQUESTS_PER_MINUTE`,
                  parseInt(e.target.value, 10),
                )
              }
              className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 text-base focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInputs = (
    data: Record<string, any>,
    path: string = "",
  ): JSX.Element[] => {
    return Object.entries(data).map(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;
      const inputValue = getNestedValue(
        currentFormValues[activeTab] || {},
        currentPath.split("."),
      );
      const [parsedValue, isJson] = safeParseJson(value);

      if (key === "HIDDEN_TAGS") {
        return (
          <div key={currentPath}>
            {renderSelectedTags(`${activeTab}.${currentPath}`)}
            {renderTagDropdown(`${activeTab}.${currentPath}`)}
          </div>
        );
      } else if (key === "OPENAI_ENDPOINTS") {
        return (
          <>
            <div
              key={currentPath}
              className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md"
            >
              <label
                htmlFor={currentPath}
                className="text-gray-700 dark:text-gray-300 text-m font-medium"
              >
                {currentPath}
              </label>
              <DynamicTable
                {...{
                  key: currentPath,
                  label: currentPath,
                  value: parsedValue,
                }}
                initialData={parsedValue}
                onDataChange={(newData: any) =>
                  handleChange(`${activeTab}.${currentPath}`, newData)
                }
              />
            </div>
            {renderRateLimits(preferences.rate_limits)}
          </>
        );
      } else if (
        ["ACCESS_GROUP_RISK_LABEL", "VECTOR_STORE_BACKEND"].includes(key)
      ) {
        return (
          <ConfigurationSelect
            {...{
              key: currentPath,
              label: currentPath,
              value: parsedValue,
              options:
                key === "ACCESS_GROUP_RISK_LABEL"
                  ? RISK_LEVEL_OPTIONS
                  : VECTOR_DB_OPTIONS,
            }}
          />
        );
      } else if (typeof inputValue === "boolean") {
        return (
          <ConfigurationCheckbox
            key={currentPath}
            label={currentPath}
            checked={inputValue}
          />
        );
      } else if (isJson || typeof inputValue === "object") {
        return (
          <div
            key={currentPath}
            className="bg-gray-100 p-3 mt-5 rounded-lg shadow-md"
          >
            <label
              htmlFor={currentPath}
              className="text-gray-700 dark:text-gray-300 text-m font-medium"
            >
              {currentPath}
            </label>
            <JsonView
              src={parsedValue}
              onAdd={(params) => {
                handleChangeWrapper(`${currentPath}`, params.src);
              }}
              onEdit={(params) => {
                handleChangeWrapper(
                  `${currentPath}`,
                  params.parentType === "object" ? params.src : params.newValue,
                );
              }}
              onDelete={(params) => {
                handleChangeWrapper(
                  `${currentPath}`,
                  params.parentType === "object" ? params.src : params.value,
                );
              }}
              theme="default"
              enableClipboard={true}
              editable={true}
            />
          </div>
        );
      } else {
        return (
          <ConfigurationInput
            {...{
              key: currentPath,
              label: currentPath,
              type: typeof inputValue === "number" ? "number" : "text",
              value: inputValue,
            }}
          />
        );
      }
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    currentPath: string,
  ) => {
    setSelectedTag(e.target.value);
  };

  const addTag = (currentPath: string) => {
    if (selectedTag && !selectedTags.includes(selectedTag)) {
      const updatedTags = [...selectedTags, selectedTag];
      handleChange(currentPath, updatedTags);
      setSelectedTag("");
      setSelectedTags(updatedTags);
    }
  };

  const removeTag = (tagToRemove: string, currentPath: string) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    handleChange(currentPath, updatedTags);
    setSelectedTags(updatedTags);
  };

  const renderTagDropdown = (currentPath: string): JSX.Element => {
    const tags = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
      ...Object.fromEntries(
        preferences.system.EXCLUDE_TAGS.map((tag: any) => [tag, true]),
      ),
    });

    return (
      <div className="flex items-center gap-2 bg-white p-4 rounded-lg shadow">
        <select
          className="form-select flex-grow border border-gray-300 rounded-md text-gray-700 p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-150 ease-in-out"
          value={selectedTag}
          onChange={(e) => handleSelectChange(e, currentPath)}
        >
          {tags.map(
            (tag) =>
              !selectedTags.includes(tag) && (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ),
          )}
        </select>
        <button
          type="button"
          className="px-4 py-2 bg-green-600 text-white font-semibold rounded-md hover:bg-green-600 transition duration-150 ease-in-out"
          onClick={() => addTag(currentPath)}
          aria-label="Add tag"
        >
          + Add Tag
        </button>
      </div>
    );
  };

  const renderSelectedTags = (currentPath: string): JSX.Element => {
    return (
      <ul className="flex flex-wrap gap-2 p-4 bg-white rounded-lg shadow">
        {selectedTags.map((tag) => (
          <li
            key={tag}
            className="bg-gray-200 rounded-full px-3 py-1 flex items-center"
          >
            {tag}
            <button
              type="button"
              className="ml-2 text-red-500 hover:text-red-700 focus:outline-none focus:text-red-800"
              onClick={() => removeTag(tag, currentPath)}
              aria-label={`Remove ${tag}`}
            >
              ✕
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const getNestedValue = (
    obj: Record<string, any>,
    pathArray: string[],
  ): any => {
    return pathArray.reduce((acc, key) => {
      return acc ? acc[key] : null;
    }, obj);
  };

  if (!preferences) {
    return <div>Loading preferences...</div>;
  }

  return (
    <div>
      {activeTab === "profile" && (
        <div>{renderInputs(preferences.profile)}</div>
      )}
      {activeTab === "hidden_tags" && (
        <div>{renderInputs(preferences.hidden_tags)}</div>
      )}
      {activeTab === "webapp_profile" && (
        <div>{renderInputs(preferences.webapp_profile)}</div>
      )}
    </div>
  );
}
