import { faCloudUpload, faFax } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC, useState } from "react";
import { useFileUplaodMutation } from "../../../api/queryHooks";
import { toast } from "../Toast";

import "./styles.css";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  dataStore: {
    type: "s3" | "azureblob";
    name: string;
    base_path: string;
    credentials:
      | {
          access_key_id: string;
          secret_access_key: string;
        }
      | {
          client_id: string;
          client_secret: string;
          tenant_id: string;
        };
  };
};

export const FileUploadModal: FC<Props> = ({
  onClose,
  dataStore,
  onSuccess,
}) => {
  const resetAndClose = () => {
    setFiles([]);
    onClose();
  };

  const filesUplaodMutation = useFileUplaodMutation(
    { storage: dataStore },
    {
      onSuccess: () => {
        resetAndClose();
        toast.success({
          title: "Files uploaded successfully",
          description: "",
        });
        onSuccess();
      },
      onError: () => {
        toast.error({
          title: "Failed to upload files",
          description: "Try again",
        });
      },
    },
  );
  const [files, setFiles] = useState<File[]>([]);

  const submit = () => {
    filesUplaodMutation.mutate({ files });
  };

  return (
    <Modal open onClose={resetAndClose}>
      <>
        {filesUplaodMutation.isPending && (
          <Box className="FileUploadLoadingIndicator">
            <CircularProgress />
          </Box>
        )}
        {!filesUplaodMutation.isPending && (
          <Box className="FileUploadContainer">
            <Typography variant="h4">Upload a files</Typography>
            <ul>
              {files.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
            <Button
              component="label"
              startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
              role={undefined}
              variant="contained"
            >
              Select Files
              <input
                type="file"
                accept="image/jpeg, image/gif, image/png, .pdf, .doc, .docx, .txt, .application/msword, .xlsx, .xls"
                onChange={(e) => setFiles(Array.from(e.target.files || []))}
                multiple
              />
            </Button>
            <Box className="FileUploadControls">
              <Button type="reset" variant="outlined" onClick={resetAndClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={submit}
                disabled={!files.length || filesUplaodMutation.isPending}
                variant="contained"
              >
                Upload
              </Button>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
