import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Radio,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ListSubheader,
  TableContainer,
} from "@mui/material";
import "./styles.css";
import { useUsers, updateGroups } from "./../../../hooks/Users";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "./../Toast";

export const TeamManager = ({ onClose }) => {
  const users = useUsers();
  const [isLoading, setIsloading] = useState(false);
  const [snapshot, setSnapshot] = useState([]);
  const [catalogTeams, setCatalogTeams] = useState([]);

  const isOk = useMemo(
    () => !users.isLoading && !users.isError && !isLoading,
    [users, isLoading],
  );

  useEffect(() => {
    if (users.isLoading) {
      return;
    }

    if (users.isError) {
      toast.error({ title: "Failed to load teams" });
      onClose();
    }

    setSnapshot(users.users);
    setCatalogTeams(users.catalogTeams);
    setIsloading(false);
  }, [users]);

  const submit = useCallback(() => {
    setIsloading(true);

    updateGroups({
      users: snapshot,
      catalog_teams: catalogTeams,
    })
      .then(() => {
        toast.success({ title: "Teams updated" });
        onClose();
      })
      .catch((error) => {
        console.error(error);
        toast.error({ title: "Failed to update teams" });
        onClose();
      });
  }, [snapshot, onClose]);

  const updateSnapshot = useCallback(
    (email, key, value) => {
      const newSnapshot = snapshot.map((user) => {
        if (user.email === email) {
          return { ...user, [key]: value };
        }
        return user;
      });

      setSnapshot(newSnapshot);
    },
    [snapshot],
  );

  return (
    <Modal open onClose={onClose}>
      <>
        {!isOk && (
          <Box className="TeamManagerLoadingIndicator">
            <CircularProgress />
          </Box>
        )}
        {isOk && (
          <Box className="TeamManagerContainer">
            <Typography variant="h4">Manage user groups</Typography>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Teams</TableCell>
                    {users.teams.map((team) => (
                      <TableCell key={team}>{team}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {snapshot.map(({ email, team, catalog_team = "" }) => (
                    <TableRow key={email}>
                      <TableCell>{email}</TableCell>
                      <TableCell>
                        <Select
                          value={catalog_team}
                          onChange={(e) => {
                            if (e.target.value === "Add new team") {
                              const newTeam = prompt("Enter new team name");
                              updateSnapshot(email, "catalog_team", newTeam);
                              setCatalogTeams((old) => [...old, newTeam]);
                            } else {
                              updateSnapshot(
                                email,
                                "catalog_team",
                                e.target.value,
                              );
                            }
                          }}
                        >
                          {catalogTeams.map((catalogTeam) => (
                            <MenuItem value={catalogTeam} key={catalogTeam}>
                              {catalogTeam}
                            </MenuItem>
                          ))}
                          <ListSubheader>---</ListSubheader>
                          <MenuItem value={null}>No team</MenuItem>
                          <MenuItem value="Add new team">Add new team</MenuItem>
                        </Select>
                      </TableCell>
                      {users.teams.map((t) => (
                        <TableCell key={t}>
                          <Radio
                            onClick={() => updateSnapshot(email, "team", t)}
                            checked={team === t}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="TeamManagerFooter">
              <Button type="reset" variant="outlined" onClick={onClose}>
                Cancel
              </Button>

              <Button type="submit" variant="contained" onClick={submit}>
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
