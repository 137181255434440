import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { NoDataComponent } from "./NoDataComponent";
import { GraphProps } from "./types";
export const TokenUsageGraph: React.FC<GraphProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return <NoDataComponent />;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          yAxisId="left"
          dataKey="promptTokens"
          fill="#8884d8"
          name="Prompt Tokens"
          stackId="a"
        />
        <Bar
          yAxisId="left"
          dataKey="completionTokens"
          fill="#82ca9d"
          name="Completion Tokens"
          stackId="a"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="totalCost"
          stroke="#ff7300"
          name="Total Cost ($)"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
