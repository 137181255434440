import { useEffect, useState, useContext, useCallback } from "react";
import { MdImportContacts, MdMenuBook } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { GrSelection } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { generateRandomNumber } from "../../../../../../utilities/functions/utils";
import { UsecaseContext } from "../../../../../../../context/UsecaseContext";
import { DataContext } from "../../../../../../../context/DataContext";
import MetadataFilterComponent from "./Components/MetadataFilterComponent/MetadataFilterComponent";
import DataFilterComponent from "./Components/DataFilterComponent/DataFilterComponent";
import { useCatalogNames } from "../../../../../../../api/queryHooks";

const UseCaseDefinitionStepForm = ({ label, icon, selected, onClick }) => {
  return (
    <div className="flex flex-col gap-2 cursor-pointer" onClick={onClick}>
      <div className="flex items-center justify-center">
        <div
          className={`text-4xl rounded-full flex justify-center items-center w-12 h-12 text-white transition-all duration-500 ${
            selected
              ? "bg-primary shadow-[0px_0px_10px_5px_rgba(45,255,196,0.4)]"
              : "bg-zinc-500"
          }`}
        >
          {icon}
        </div>
      </div>
      <div>{label}</div>
    </div>
  );
};

const steps = [
  {
    id: "generalSettings",
    title: `Start by specifying the basic details of your use case. Define the type, provide a clear description, and set the foundational aspects to guide the data selection process.`,
    label: "General Information",
    icon: <AiOutlineInfoCircle className="text-3xl" />,
  },
  {
    id: "catalogSelection",
    title: `Specify the catalog from which you would like pull the data from.`,
    label: "Catalog Selection",
    icon: <MdMenuBook />,
  },
  {
    id: "dataSelection",
    title: `Select the datasets and tags that will feed into your use case. Customize your data selection with filters to refine and optimize the data provided to your AI model or DeasieMind.`,
    label: "Data Selection",
    icon: <GrSelection className="text-2xl" />,
  },
];

function* getTextInChunks(text) {
  for (const str of text) {
    yield str;
  }
}

const DefineUseCaseSection = () => {
  const [title, setTitle] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const {
    currentUseCase,
    addedData,
    setAddedData,
    confirmUsecaseData,
    showDataFilter,
    setShowDataFilter,
    setCurrentUseCase,
    setShowMetadataFilter,
    showMetadataFilter,
    setSelectedMetadata,
    addUseCase,
    setUsecaseStage,
  } = useContext(UsecaseContext);

  const {
    showScreen,
    catalogSummary,
    catalogFiles,
    handleCatalogChange,
    usedCatalog,
  } = useContext(DataContext);

  const { data: catalogNames = [] } = useCatalogNames();

  const handleInputChange = useCallback(
    (key, value) => {
      setCurrentUseCase((prevUseCase) => {
        if (prevUseCase[key] !== value) {
          return {
            ...prevUseCase,
            [key]: value,
          };
        }
        return prevUseCase;
      });
    },
    [setCurrentUseCase]
  );

  useEffect(() => {
    setAddedData(currentUseCase.usecase_catalog);
    setSelectedMetadata(currentUseCase.metadata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUseCase.id]);

  useEffect(() => {
    if (
      !currentUseCase.usecase_catalog ||
      Object.keys(currentUseCase.usecase_catalog).length === 0
    ) {
      handleInputChange("id", generateRandomNumber().toString());
    }
  }, [currentUseCase.usecase_catalog, handleInputChange]);

  const {
    register,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      generalInformation: {
        name: "",
        type: "",
        description: "",
      },
      catalogSelection: {
        catalog_name: "",
      },
    },
  });

  useEffect(() => {
    setValue("generalInformation.name", currentUseCase.name || "");
    setValue("generalInformation.description", currentUseCase.description);
    setValue(
      "catalogSelection.catalog_name",
      currentUseCase.catalog_name || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const generator = getTextInChunks(steps[currentStep].title);
    setTitle("");
    const interval = setInterval(() => {
      const charIteratorValue = generator.next().value;
      if (!charIteratorValue) return clearInterval(interval);
      setTitle((cTitle) => `${cTitle}${charIteratorValue}`);
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  const handleNextStep = useCallback(() => {
    const data = watch();
    if (currentStep === 0) {
      if (!data.generalInformation.name) {
        setError("generalInformation.name", {
          message: "Please, input the name of the usecase",
        });
      }
      if (!data.generalInformation.description) {
        setError("generalInformation.description", {
          message: "Please, input your description",
        });
      }
      if (
        data.generalInformation.name &&
        data.generalInformation.description
      ) {
        setCurrentStep((step) => step + 1);
        setError("generalInformation.description", undefined);
        handleInputChange("name", data.generalInformation.name);
        handleInputChange("description", data.generalInformation.description);
      }
    }

    if (currentStep === 1) {
      let catalogName = data.catalogSelection.catalog_name;

      if (!catalogName && catalogNames.length > 0) {
        catalogName = catalogNames[0];
      }

      if (!catalogName) {
        setError("catalogSelection.catalog_name", {
          message: "Please, select catalog name",
        });
      }

      if (catalogName) {
        setCurrentStep((step) => step + 1);
        setError("catalogSelection.catalog_name", undefined);
        handleInputChange("catalog_name", catalogName);
      }
    }

    if (currentStep === 2) {
      addUseCase();
    }
  }, [addUseCase, currentStep, handleInputChange, setError, watch]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 usecase-container relative text-sm">
      <div className="absolute inset-0 flex justify-center p-4 dark:bg-black">
        <div className="w-full flex flex-col gap-4 max-w-lg dark:text-white">
          <div className="text-xs flex justify-between gap-4 mt-16">
            {steps.map((step, index) => (
              <UseCaseDefinitionStepForm
                key={`step-${step.label}-${index}`}
                onClick={() => {
                  const data = watch();
                  if (
                    data.generalInformation.name &&
                    data.generalInformation.description &&
                    data.catalogSelection.catalog_name
                  ) {
                    setCurrentStep(index);
                  }
                }}
                selected={currentStep === index}
                label={step.label}
                icon={step.icon}
              />
            ))}
          </div>
          <div className="relative">
            <div className="opacity-0">{steps[currentStep].title}</div>
            <div className="absolute inset-0">
              {steps[currentStep].title !== title ? (
                <>
                  {title.substring(0, title.length - 2)}
                  <span className="text-primary shadow-[0px_0px_10px_0px_rgba(45,255,196,0.4)] bg-primary bg-opacity-20">
                    {title.substring(title.length - 2)}
                  </span>
                </>
              ) : (
                title
              )}
            </div>
          </div>
          {currentStep === 0 && (
            <div className="dark:bg-zinc-900 bg-slate-100 p-4 flex flex-col gap-4">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <div className="text-xs">Name</div>
                  <input
                    {...register("generalInformation.name")}
                    className="resize-none px-4 py-2 outline-none dark:bg-transparent dark:border-zinc-700 border"
                    placeholder="Your usecase name"
                  />
                </div>
                {errors.generalInformation?.name && (
                  <div className="text-xs text-red-400">
                    {errors.generalInformation?.name?.message}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <div className="text-xs">Description</div>
                  <textarea
                    {...register("generalInformation.description")}
                    className="resize-none px-4 py-2 outline-none dark:bg-transparent dark:border-zinc-700 border"
                    placeholder="Describe your Usecase"
                    rows={2}
                  />
                </div>
                {errors.generalInformation?.description && (
                  <div className="text-xs text-red-400">
                    {errors.generalInformation?.description?.message}
                  </div>
                )}
              </div>
            </div>
          )}
          {currentStep === 1 && (
            <div className="dark:bg-zinc-900 bg-slate-100 p-4 flex flex-col gap-4">
              <div>
                <div className="flex flex-col gap-2">
                  <div className="text-xs">Catalog</div>
                  <select
                    className={`px-4 py-2 outline-none dark:bg-zinc-900 dark:border-zinc-700 border ${
                      watch("catalogSelection.catalog_name")
                        ? "dark:text-white"
                        : "dark:text-zinc-400"
                    }`}
                    {...register("catalogSelection.catalog_name")}
                    placeholder="Select an existing catalog"
                    onChange={handleCatalogChange}
                    value={usedCatalog || ""}
                  >
                    <option value="" className="dark:bg-zinc-800">
                      Select an existing catalog
                    </option>
                    {catalogNames.map((catalog, index) => (
                      <option
                        key={index}
                        value={catalog}
                        className="dark:bg-zinc-800"
                      >
                        {catalog}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.catalogSelection?.catalog_name && (
                  <div className="text-xs text-red-400">
                    {errors.catalogSelection?.catalog_name?.message}
                  </div>
                )}
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="dark:bg-zinc-900 bg-slate-100 p-4 flex flex-col gap-4">
              <div
                className="border dark:border-zinc-700 w-full p-4 transition-all cursor-pointer select-none dark:hover:bg-zinc-950 hover:bg-white"
                onClick={() => {
                  setShowDataFilter(true);
                }}
              >
                <div className="flex flex-col gap-2">
                  <div className="text-xs">
                    Datasets selected (
                    {Object.keys(currentUseCase.usecase_catalog || {}).length})
                  </div>
                </div>
              </div>

              <div
                className="border dark:border-zinc-700 w-full p-4 transition-all cursor-pointer select-none dark:hover:bg-zinc-950 hover:bg-white"
                onClick={() => {
                  setShowMetadataFilter(true);
                }}
              >
                <div className="flex flex-col gap-2">
                  <div className="text-xs">
                    Metadata selected (
                    {Object.keys(currentUseCase.metadata || {}).length})
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-4">
            <button
              className={`border dark:border-zinc-700 w-full p-4 ${
                currentStep === 0
                  ? "bg-primary hover:bg-secondary text-white"
                  : "dark:hover:bg-zinc-900 hover:bg-slate-100"
              }`}
              onClick={() => {
                if (currentStep) {
                  return setCurrentStep((step) => step - 1);
                }
                setUsecaseStage("menu");
              }}
            >
              {currentStep === 0 ? "CANCEL" : "BACK"}
            </button>
            <button
              disabled={
                currentStep === 2 &&
                Object.keys(currentUseCase.usecase_catalog || {}).length === 0
              }
              className={`border dark:border-zinc-700 w-full p-4 transition-all ${
                currentStep === 2
                  ? "bg-primary hover:bg-secondary text-white"
                  : "dark:hover:bg-zinc-900 hover:bg-slate-100"
              } ${
                currentStep === 2 &&
                Object.keys(currentUseCase.usecase_catalog || {}).length === 0
                  ? "bg-opacity-50"
                  : "bg-opacity-100"
              }`}
              onClick={() => {
                handleNextStep();
              }}
            >
              {currentStep === 2 ? "CONFIRM" : "NEXT"}
            </button>
          </div>
        </div>
      </div>
      {showMetadataFilter && (
        <MetadataFilterComponent
          catalogSummary={catalogSummary}
          catalogFiles={catalogFiles}
          isModalOpen={showMetadataFilter}
          setIsModalOpen={setShowMetadataFilter}
          addedData={addedData}
          setAddedData={setAddedData}
          confirmUsecaseData={confirmUsecaseData}
          currentUseCase={currentUseCase}
        />
      )}
      {showDataFilter && (
        <DataFilterComponent
          catalogSummary={catalogSummary}
          catalogFiles={catalogFiles}
          isModalOpen={showDataFilter}
          setIsModalOpen={setShowDataFilter}
          addedData={addedData}
          setAddedData={setAddedData}
          confirmUsecaseData={confirmUsecaseData}
          currentUseCase={currentUseCase}
          showScreen={showScreen}
        />
      )}
    </div>
  );
};

export default DefineUseCaseSection;
