import {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import Auth from "../auth/AuthProvider";

import {
  updateCatalog,
  getRules,
  renameCatalog,
} from "../components/utilities/functions/apiCalls";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../constants/labelConfig";

import {
  deleteLabelParentLabelObj,
  updateModelVersion,
} from "../components/utilities/functions/utils";
import { toast } from "../components/utilities/Toast";
import { Queue } from "../utils";
import { useCatalogChangeSubscription } from "../hooks/CatalogChangeSubscription";
import { useFailedTags } from "../hooks/FailedTags";
import { useUserProfile } from "./UserProfile";
import { useCreateCatalogMutation, useCatalogNames } from "../api/queryHooks";
import { selectedCatalogItemsAtom } from "../atoms";
import { useAtom } from "jotai";
import { usePersistedState } from "../pages/hooks";

export const DataContext = createContext();

export const defaultCurrentTag = {
  name: "",
  tagType: "classification",
  description: "",
  availableValues: [],
  allow_other_values: false,
  reference_file: "",
  max_words: 1,
  examples: null,
  option: "aiGenerated",
  risk_level: "",
  risk_level_description: {
    low: "",
    medium: "",
    high: "",
  },
  type: "word",
  is_document_level: false,
  standardized: false
};

const DEFAULT_CATALOG_NAME = "catalog";

export const DataProvider = ({ children }) => {
  const userProfile = useUserProfile();
  const [preferences, setPreferences] = useState(userProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [showScreen, setShowScreen] = useState("catalog");
  const [usedCatalog, setUsedCatalog] = usePersistedState(
    "selectedCatalog",
    userProfile.system.EXISTING_CATALOG,
  );
  const [catalogSummary, setCatalogSummary] = useState({});
  const [dataGroups, setDataGroups] = useState({});
  const [catalogFiles, setCatalogFiles] = useState({});
  const [currentDataGroup, setCurrentDataGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [detectedDataGroup, setDetectedGroup] = useState({});
  const [useCases, setUseCases] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [usecaseSelected, setUsecaseSelected] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(-1);
  const [availableTags, setAvailableTags] = useState({});
  const [availableRules, setAvailableRules] = useState({});
  const [ruleDict, setRuleDict] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [quarantinedFiles, setQuarantinedFiles] = useState({});
  const [processingFile, setProcessingFile] = useState(-1);
  const [showConnectData, setShowConnectData] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [evidenceData, setEvidenceData] = useState([]);

  // TODO: change the current reference to DEFAULT_TAG parameter
  const [currentTag, setCurrentTag] = useState({
    name: "",
    tagType: "classification",
    description: "",
    availableValues: [],
    allow_other_values: false,
    reference_file: "",
    max_words: 1,
    examples: null,
    option: "aiGenerated",
    risk_level: "",
    risk_level_description: {
      low: "",
      medium: "",
      high: "",
    },
    type: "word",
  });
  const [currentProcessCount, setCurrentProcessCount] = useState(0);
  const [currentTotalProcessCount, setCurrentTotalProcessCount] = useState(0);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [startPoint, setStartPoint] = useState(0);
  const deleteTagsQueueRef = useRef(new Queue());
  const fetchCatalogQueueRef = useRef(new Queue({ maxTasks: 2 }));
  const [tagsToBeDeleted, setTagsToBeDeleted] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [view, setView] = useState("options");
  const [, setSelectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const { failedTags, tagReRun, hasTagFailedFor, failedTagDeleted } =
    useFailedTags(currentDataGroup);

  const createCatalogMutation = useCreateCatalogMutation();

  useEffect(() => {
    setSelectedCatalogItems(new Set());
  }, [setSelectedCatalogItems, usedCatalog]);

  const fetchInitialCatalog = useCallback(
    async (catalog_name = null) => {
      try {
        const [SafeFiles, UnsafeFiles] = await Promise.all([
          sendRequest(
            {
              catalog_name: catalog_name,
              [preferences.system.API_USERNAME_KEYWORD]: (
                await Auth.currentAuthenticatedUser()
              ).username,
            },
            ENDPOINTS["get_catalog"],
          ).then((r) => r.json()),
          sendRequest(
            {
              catalog_name: preferences.system.QUARANTINECATALOG,
              [preferences.system.API_USERNAME_KEYWORD]: (
                await Auth.currentAuthenticatedUser()
              ).username,
            },
            ENDPOINTS["get_catalog"],
          ).then((r) => r.json()),
        ]);

        for (const key in UnsafeFiles.catalog) {
          if (SafeFiles.catalog[key]) {
            delete SafeFiles.catalog[key];
          }
        }

        setCatalogFiles(SafeFiles.catalog);
        setCatalogSummary(SafeFiles.filter_map);
        setCurrentDataGroup(SafeFiles.catalog);
        setSearchDetails(SafeFiles.search_details);
        setQuarantinedFiles(UnsafeFiles.catalog);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [
      preferences.system.QUARANTINECATALOG,
      preferences.system.API_USERNAME_KEYWORD,
    ],
  );
  const handleTableUpdate = useCallback(() => {
    fetchCatalogQueueRef.current.addNewTask(async () => {
      await fetchInitialCatalog(usedCatalog);
    }, true);
  }, [usedCatalog, fetchInitialCatalog]);

  const handleQuarantinedFileChange = useCallback((fileName, fileEntry) => {
    setQuarantinedFiles((prevQuarantinedFiles) => ({
      ...prevQuarantinedFiles,
      [fileName]: fileEntry,
    }));
  }, []);

  useCatalogChangeSubscription({
    activeCatalog: usedCatalog,
    onChange: handleTableUpdate,
    onQuarantinedFileChange: handleQuarantinedFileChange,
  });

  const {
    isSuccess: wereCatalogNamesSuccesfullyFetched,
    data: catalogNames = [],
    refetch: refetchCatalogNames,
  } = useCatalogNames();

  const hasToCreateDefaultCatalog = useMemo(() => {
    return (
      !catalogNames.includes(DEFAULT_CATALOG_NAME) &&
      wereCatalogNamesSuccesfullyFetched
    );
  }, [catalogNames, wereCatalogNamesSuccesfullyFetched]);

  useEffect(() => {
    if (!hasToCreateDefaultCatalog) return;
    (async () => {
      await createCatalogMutation.mutateAsync(DEFAULT_CATALOG_NAME, {
        key: "catalogAutoCreation",
      });
      refetchCatalogNames();
    })();
  }, [hasToCreateDefaultCatalog, refetchCatalogNames, setUsedCatalog]);

  useEffect(() => {
    getRules(usedCatalog)
      .then((fetchedRules) => {
        const acc = {};
        const rulesToTagDict = fetchedRules.map((rule) => {
          const conditionsDescription = rule.rules
            .map(
              (condition) =>
                `if '${condition.tag_name}' equals '${condition.value}'`,
            )
            .join(" and ");

          const ruleDict = {
            name: rule.output_tag,
            tagType: "rule",
            description: `Apply this tag on conditions: ${conditionsDescription}.`,
            availableValues: [],
            allow_other_values: false,
            reference_file: "",
            max_words: 1,
            examples: null,
            option: "aiGenerated",
            risk_level: "",
            risk_level_description: {
              low: "",
              medium: "",
              high: "",
            },
            type: "word",
          };

          acc[rule.output_tag] = ruleDict;
          return acc;
        });
        setRuleDict(acc);
      })
      .catch((error) => {
        console.error("Failed to fetch rules:", error);
      });
  }, [usedCatalog]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setPreferences(userProfile);
        setAvailableTags(userProfile.system.TAGGER_LIST);
        const hiddenTags = userProfile.hidden_tags.HIDDEN_TAGS;
        setHiddenCategories([...hiddenCategories, ...hiddenTags]);
      } catch (error) {
        console.error("Error fetching preferences:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPreferences();
  }, []);

  const handleLabelChange = async (itemKey, labelKey, newValue) => {
    const afterLabelChange = (prevState) => ({
      ...prevState,
      [itemKey]: {
        ...prevState[itemKey],
        [labelKey]: newValue,
      },
    });
    setCurrentDataGroup(afterLabelChange);
    setCatalogFiles(afterLabelChange);

    await updateCatalog(usedCatalog, afterLabelChange(catalogFiles));
    setModalOpen(false);
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const handleLabelDelete = async (itemKey, labelKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${labelKey}"?`,
    );
    if (!confirmDelete) {
      return;
    }

    const afterLabelDelete = (prevState) => {
      const updatedItem = { ...prevState[itemKey] };
      delete updatedItem[labelKey];
      return { ...prevState, [itemKey]: updatedItem };
    };

    setCurrentDataGroup(afterLabelDelete);
    setCatalogFiles(afterLabelDelete);

    setModalOpen(false);
  };

  const deleteAllLabel = async (e, tagName) => {
    e.stopPropagation();
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${tagName}"?`,
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing tag ${tagName} to be deleted`,
    });

    setTagsToBeDeleted((prev) => [...prev, tagName]);

    const NEW_TAGGER_LIST = deleteLabelParentLabelObj(availableTags, tagName);

    const rawResponse = await sendRequest(
      {
        catalog: JSON.stringify(catalogFiles),
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        label: tagName,
        catalog_name: usedCatalog,
        new_tagger_list: JSON.stringify(NEW_TAGGER_LIST),
      },
      ENDPOINTS["delete_label"],
    );
    const response = await rawResponse.json();

    toast.success({
      title: "Success",
      description: `Tag ${tagName} successfully deleted`,
    });

    setAvailableTags(NEW_TAGGER_LIST);
    setTagsToBeDeleted((prev) => prev.filter((tag) => tag !== tagName));
    failedTagDeleted(tagName);

    return response;
  };

  const handleDatasetDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`,
    );
    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });
    if (!confirmDelete) {
      return;
    }

    try {
      const afterDataDelete = (prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      };

      setCurrentDataGroup(afterDataDelete);
      setCatalogFiles(afterDataDelete);

      await updateCatalog(usedCatalog, afterDataDelete(catalogFiles));
      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey}`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error,
        )}`,
      });
    }
  };

  const handleQuarantineDataDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`,
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });

    try {
      setQuarantinedFiles((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      });

      const updatedCatalog = afterDataDelete(quarantinedFiles);
      await updateCatalog(preferences.system.QUARANTINECATALOG, updatedCatalog);

      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey} from quarantine`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error,
        )}`,
      });
    }
  };

  const afterDataDelete = (prevState, itemKey) => {
    const updatedState = { ...prevState };
    delete updatedState[itemKey];
    return updatedState;
  };

  const deleteMultipleQuarantine = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the quarantine?`,
      )
    ) {
      return;
    }

    const newDataGroup = { ...quarantinedFiles };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(preferences.system.QUARANTINECATALOG, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error,
        )}`,
      });
    }
  };

  const deleteMultipleDatasets = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the catalog?`,
      )
    ) {
      return;
    }

    const newDataGroup = { ...currentDataGroup };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(usedCatalog, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error,
        )}`,
      });
    }
  };

  const handleMultipleDelete = () => {
    const allKeys = Object.keys(currentDataGroup);
    deleteMultipleDatasets(allKeys);
    setSelectedFilters({});
  };

  const updateTagDict = (newTagDict) => {
    const cleanedTagDict = Object.keys(newTagDict)
      .filter((key) => !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(key))
      .reduce((obj, key) => {
        obj[key] = availableTags.llm.tagger_params.tag_dict[key];
        return obj;
      }, {});

    setAvailableTags((prevState) => ({
      ...prevState,
      llm: {
        ...prevState.llm,
        tagger_params: {
          ...prevState.llm.tagger_params,
          tag_dict: cleanedTagDict,
        },
      },
    }));
  };

  const fetchInitialTaggerList = async (catalog_name = null) => {
    const rawResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: catalog_name || usedCatalog,
      },
      ENDPOINTS["get_tags"],
    );
    const response = await rawResponse.json();
    if (Object.keys(response.tags).length > 0) {
      let updatedTaggers = updateModelVersion(
        response.tags,
        preferences.webapp_profile.MODEL_USED,
        preferences.webapp_profile.PROVIDER_USED,
      );
      setAvailableTags(() => {
        console.debug("fetchInitialTaggerList");
        return updatedTaggers;
      });
    } else {
      setAvailableTags(() => {
        console.debug("fetchInitialTaggerList");
        return preferences.system.TAGGER_LIST;
      });
    }
  };

  const fetchInitialUsecases = async () => {
    try {
      const rawResponse = await sendRequest(
        {
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["get_all_usecases"],
      );
      const response = await rawResponse.json();
      setUseCases(response.usecases);
    } catch (error) {
      setUseCases({});
      console.error("Error fetching data:", error);
    }
  };

  const toggleCategoryVisibility = (e, category) => {
    e.stopPropagation();
    if (hiddenCategories.includes(category)) {
      setHiddenCategories(hiddenCategories.filter((c) => c !== category));
      toast.success({
        title: "Success",
        description: `Category ${category} successfully unhidden`,
      });
    } else {
      setHiddenCategories([...hiddenCategories, category]);
      toast.success({
        title: "Success",
        description: `Category ${category} successfully hidden`,
      });
    }
  };

  const handleCatalogChange = async (event) => {
    const value = event.target.value;
    if (value === "newCatalog") {
      const catalogName = prompt("Enter new catalog name:");
      if (catalogName) {
        createCatalogMutation.mutate(catalogName, {
          onSuccess: () => {
            setUsedCatalog(catalogName);
            toast.success({
              title: "Success",
              description: "Catalog created successfully!",
            });
          },
          onError: () => {
            toast.error({
              title: "Error",
              description: "Failed to create catalog.",
            });
          },
        });
      }
    } else {
      toast.info({
        title: "Info",
        description: "Loading catalog " + value,
      });
      setUsedCatalog(value);
    }
    await fetchInitialCatalog(value);
    await fetchInitialTaggerList(value);
    toast.success({
      title: "Success",
      description: `
      Successfully loaded datasets from ${value}`,
    });
  };

  const handleCatalogRename = async (oldName, newName) => {
    try {
      await renameCatalog(newName, oldName);
      setUsedCatalog(newName);
      await fetchInitialCatalog(newName);
      await fetchInitialTaggerList(newName);
      toast.success({
        title: "Success",
        description: `Successfully renamed to ${newName}`,
      });
    } catch (err) {
      setUsedCatalog(oldName);
      console.error("Failed...");
    }
  };

  const handleCatalogRenameByName = async (newName, oldName) => {
    try {
      await renameCatalog(newName, oldName);
      toast.success({
        title: "Success",
        description: `Successfully renamed to ${newName}`,
      });
    } catch (err) {
      toast.error("Failed to rename catalog.");
    }
  };

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  const handleEvidenceButtonClick = (selectedFiles) => {
    const allEvidenceData = [];
    Object.keys(currentDataGroup).forEach((itemKey) => {
      const item = currentDataGroup[itemKey];
      const groupedEvidence = {};
      const fileName = itemKey;

      if (item.hasOwnProperty("chunks")) {
        Object.entries(item.chunks).forEach(([chunkKey, chunkValue]) => {
          Object.entries(chunkValue).forEach(([tag, details]) => {
            if (!groupedEvidence[tag]) {
              groupedEvidence[tag] = [];
            }

            // Check if the fileName is in the selectedFiles array
            if (
              (!selectedFiles || selectedFiles.includes(itemKey)) &&
              details.evidence
            ) {
              groupedEvidence[tag].push({
                chunk: chunkKey,
                evidence: details.evidence,
                reason: details.reason,
                values: details.value,
                isValid: details.isValid ?? true,
                fileName: fileName,
              });
            }
          });
        });
      }

      const evidenceList = Object.entries(groupedEvidence)
        .map(([tag, evidences]) => ({
          tag,
          evidences,
        }))
        .filter(({ evidences }) => evidences.length > 0);

      allEvidenceData.push(...evidenceList);
    });

    setEvidenceData(allEvidenceData);

    setIsEvidenceModalOpen(true);
  };

  return (
    <DataContext.Provider
      value={{
        // Getters
        isLoading,
        showScreen,
        usedCatalog,
        catalogSummary,
        dataGroups,
        catalogFiles,
        currentDataGroup,
        searchTerm,
        detectedDataGroup,
        useCases,
        usecaseSelected,
        isModalOpen,
        fileUploadProgress,
        availableTags,
        availableRules,
        searchDetails,
        quarantinedFiles,
        processingFile,
        currentTag,
        currentProcessCount,
        currentTotalProcessCount,
        dateRange,
        hiddenCategories,
        selectedFilters,
        preferences,
        startPoint,
        tagsToBeDeleted,
        failedTags,
        ruleDict,
        showFilePreview,
        view,
        showConnectData,
        isEvidenceModalOpen,
        evidenceData,

        // Setters
        setShowFilePreview,
        setIsEvidenceModalOpen,
        setShowConnectData,
        setView,
        setHiddenCategories,
        setStartPoint,
        setIsLoading,
        setShowScreen,
        setUsedCatalog,
        setCatalogSummary,
        setDataGroups,
        setCatalogFiles,
        setCurrentDataGroup,
        setSearchTerm,
        setDetectedGroup,
        setUseCases,
        setDateRange,
        setUsecaseSelected,
        setModalOpen,
        setFileUploadProgress,
        setAvailableTags,
        setAvailableRules,
        setSearchDetails,
        setQuarantinedFiles,
        setProcessingFile,
        setCurrentTag,
        setCurrentProcessCount,
        setCurrentTotalProcessCount,
        setSelectedFilters,
        setPreferences,
        setRuleDict,
        setEvidenceData,
        // Functions
        handleLabelChange,
        handleLabelDelete,
        deleteAllLabel,
        handleDatasetDelete,
        updateTagDict,
        fetchInitialCatalog,
        fetchInitialTaggerList,
        fetchInitialUsecases,
        toggleCategoryVisibility,
        handleMultipleDelete,
        handleQuarantineDataDelete,
        deleteMultipleQuarantine,
        clearAllFilters,
        handleCatalogChange,
        handleCatalogRename,
        handleCatalogRenameByName,
        handleEvidenceButtonClick,
        tagReRun,
        hasTagFailedFor,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
