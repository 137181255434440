import { ENDPOINTS } from "../../../api/endpoints";
import { sendRequest } from "./api";
import Auth from "../../../auth/AuthProvider";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";

export const fetchUserTokenUsage = async (start_date, end_date) => {
  const rawResponse = await sendRequest(
    {
      start_date: start_date,
      end_date: end_date,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["get_user_token_tracking"],
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const fetchTeamTokenUsage = async (start_date, end_date) => {
  const rawResponse = await sendRequest(
    {
      start_date: start_date,
      end_date: end_date,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["get_team_token_tracking"],
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const addNewCatalog = async (catalog_name) => {
  const rawResponse = await sendRequest(
    {
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["add_new_catalog"],
  );

  if (rawResponse.ok) {
    return { success: true };
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const renameCatalog = async (new_catalog_name, old_catalog_name) => {
  const rawResponse = await sendRequest(
    {
      new_catalog_name: new_catalog_name,
      old_catalog_name: old_catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["rename_catalog"],
  );

  if (rawResponse.ok) {
    return { success: true };
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const deleteCatalog = async (catalog_name) => {
  const rawResponse = await sendRequest(
    {
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["delete_catalog"],
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const updateCatalog = async (catalog_name, catalog) => {
  await sendRequest(
    {
      catalog: JSON.stringify(catalog),
      catalog_name: catalog_name,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["upload_catalog"],
  );
};

export const uploadTags = async (tags, usedCatalog) => {
  const response = await sendRequest(
    {
      tags: JSON.stringify(tags),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
    },
    ENDPOINTS["upload_tags"],
  );
};

export const runRule = async (catalog, usedCatalog, output_tag) => {
  await sendRequest(
    {
      catalog_entry: catalog,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
      rule_output_tag: output_tag,
    },
    ENDPOINTS["run_rule"],
  );
};

export const uploadRules = async (
  rules,
  usedCatalog,
  RuleType = "rule_set",
) => {
  await sendRequest(
    {
      rules: JSON.stringify(rules),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
      rule_type: RuleType,
    },
    ENDPOINTS["upload_rules"],
  );
};

export const getRules = async (usedCatalog, ruleType = "rule_set") => {
  const rawResponse = await sendRequest(
    {
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
      catalog_name: usedCatalog,
      rule_type: ruleType,
    },
    ENDPOINTS["get_rules"],
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return jsonResponse.rules;
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const savePreferences = async (user_profile) => {
  await sendRequest(
    {
      profile: user_profile,
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["save_user_profile"],
  );
};

export const getPreferences = async () => {
  const rawResponse = await sendRequest(
    {
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    },
    ENDPOINTS["load_user_profile"],
  );

  if (rawResponse.ok) {
    const jsonResponse = await rawResponse.json();
    return { ...jsonResponse.profile, user_group: jsonResponse.user_group };
  } else {
    console.error("API call failed", await rawResponse.text());
    return null;
  }
};

export const getCatalogSummary = async (catalog) => {
  try {
    const payload = {
      catalog: JSON.stringify(catalog),
      [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    };

    const rawResponse = await sendRequest(
      payload,
      ENDPOINTS["get_catalog_summary"],
    );

    if (rawResponse.ok) {
      const jsonResponse = await rawResponse.json();
      return {
        catalog_summary: jsonResponse.catalog_summary,
        search_details: jsonResponse.search_details,
      };
    } else {
      console.error("API call failed", await rawResponse.text());
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

export async function fetchDocumentContent(
  path,
  data_stores,
  chunks = ["none"],
  source = "s3",
) {
  let dataStore = data_stores[source];
  const sendObject = {
    data_store: JSON.stringify({
      path: path,
      storage: dataStore.storage,
    }),
    [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser()).username,
    chunk_indeces: chunks,
  };

  const rawResponse = await sendRequest(
    sendObject,
    ENDPOINTS["get_document_content"],
  );
  const response = await rawResponse.json();
  return response.content_text;
}
