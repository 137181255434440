import { useState, useEffect, useContext } from "react";
import Modal from "../../../../../../../../utilities/Modal/Modal";
import "./EvidenceTable.css";
import axios from "axios";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../../../../../atoms";
import {
  DataContext,
  useDataContext,
} from "../../../../../../../../../context/DataContext";
import { sendRequest } from "../../../../../../../../../components/utilities/functions/api";
import { useUserProfile } from "../../../../../../../../../context/UserProfile";
import Auth from "../../../../../../../../../auth/AuthProvider";
import {
  useCatalogEvidenceReports,
  useEvidenceReportsMutation,
} from "../../../../../../../../../api/queryHooks";
import { toast } from "../../../../../../../../utilities/Toast";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V21H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

const cleanSearch = (text = "") => {
  return text.replace(/ /g, "").replace(/_/g, "").toLowerCase();
};

export default function EvidenceTable(props) {
  const { preferences, currentDataGroup } = useContext(DataContext);

  const [tagFilter, setTagFilter] = useState("");
  const [chunkFilter, setChunkFilter] = useState("");
  const [evidenceFilter, setEvidenceFilter] = useState("");
  const [reasonFilter, setReasonFilter] = useState("");
  const [valuesFilter, setValuesFilter] = useState("");
  const [fileNameFilter, setFileNameFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [, setPdfSearch] = useAtom(pdfSearchAtom);
  const userProfile = useUserProfile();
  const evidenceReportsMutation = useEvidenceReportsMutation();
  const { usedCatalog } = useDataContext();
  const [collapsedCollumns, setCollapsedCollumns] = useState(
    new Set(["Reason"]),
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    const isSaveDisabled = !filteredData.some(
      ({ evidence }) => !evidence.isValid || evidence.isSubmitted,
    );
    setIsSaveDisabled(isSaveDisabled);
  }, [filteredData]);

  useEffect(() => {
    setValuesFilter(
      (prev) => prev || preferences?.profile?.DEFAULT_VALUE_FILTER,
    );
  }, [preferences?.profile?.DEFAULT_VALUE_FILTER]);

  useEffect(() => {
    const newFilteredData = props.evidenceData?.flatMap(
      ({ tag, evidences }) => {
        const sortedEvidences = evidences.sort((a, b) => {
          const indexA = Object.keys(props.sortedChunkMappings).indexOf(
            a.chunk,
          );
          const indexB = Object.keys(props.sortedChunkMappings).indexOf(
            b.chunk,
          );
          return indexA - indexB;
        });

        return sortedEvidences
          .filter((evidence) => {
            const fileMatch =
              typeof evidence.fileName === "string"
                ? cleanSearch(evidence.fileName).includes(
                  cleanSearch(fileNameFilter),
                )
                : true;
            const tagMatch =
              typeof tag === "string"
                ? cleanSearch(tag).includes(cleanSearch(tagFilter))
                : true;
            const chunkMatch =
              typeof (
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk
              ) === "string"
                ? cleanSearch(
                  props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
                ).includes(cleanSearch(chunkFilter))
                : true;
            const evidenceMatch =
              typeof evidence.evidence === "string"
                ? cleanSearch(evidence.evidence).includes(
                  cleanSearch(evidenceFilter),
                )
                : true;
            const reasonMatch =
              typeof evidence.reason === "string"
                ? cleanSearch(evidence.reason).includes(
                  cleanSearch(reasonFilter),
                )
                : true;
            const valuesMatch = Array.isArray(evidence.values)
              ? evidence.values.some(
                (value) =>
                  typeof value === "string" &&
                  cleanSearch(value).includes(cleanSearch(valuesFilter)),
              )
              : true;

            return (
              fileMatch &&
              tagMatch &&
              chunkMatch &&
              evidenceMatch &&
              reasonMatch &&
              valuesMatch
            );
          })
          .map((evidence) => ({
            tag,
            evidence: {
              ...evidence,
              originalChunk: evidence.chunk,
              chunk:
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
            },
          }));
      },
    );

    setFilteredData(newFilteredData);
  }, [
    chunkFilter,
    evidenceFilter,
    props.evidenceData,
    props.sortedChunkMappings,
    reasonFilter,
    tagFilter,
    valuesFilter,
    fileNameFilter,
  ]);

  const handleDownload = () => {
    const csvRows = [
      [
        "FileName",
        "Tag",
        "Chunk",
        "Evidence",
        "Reason",
        "Values",
        "IsCorrect",
        "IsSubmitted",
      ].join(","),
      ...props.evidenceData.flatMap(({ tag, evidences }) =>
        evidences.map((evidence) =>
          [
            `"${evidence.fileName}"`,
            tag,
            evidence.chunk,
            `"${evidence.evidence}"`,
            `"${evidence.reason}"`,
            evidence.values.join("; "),
            evidence.isValid ? "Yes" : "No",
            evidence.isSubmitted ? "Yes" : "No",
          ].join(","),
        ),
      ),
    ];
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "evidence-data.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const createTagURL = "http://127.0.0.1:8000/api/tags/create";

  const createTag = async (tag_name, chunk, updatedTag) => {
    try {
      await axios.post(createTagURL, updatedTag);
    } catch (error) {
      console.error("Error creating tag:", error);
    }
  };

  const handleSave = () => {
    const payloads = pendingChanges.map((el) => ({
      tagname: el.tag,
      catalogname: usedCatalog,
      is_valid: el.updatedTag.isValid,
      is_submitted: el.updatedTag.isSubmitted,
      filename: el.updatedTag.fileName,
    }));

    evidenceReportsMutation.mutate(payloads);
    setPendingChanges([]);
    toast.success({ title: "Changes saved successfully!" });
  };

  const handleInputChange = (tag, evidence, field, value) => {
    const updatedTag = {
      ...evidence,
      [field]: value,
    };

    const changeKey = `${tag}_${evidence.chunk}_${field}`;
    const existingChangeIndex = pendingChanges.findIndex(
      (change) =>
        change.tag === tag &&
        change.chunk === evidence.chunk &&
        change.field === field,
    );

    if (existingChangeIndex !== -1) {
      const updatedChanges = [...pendingChanges];
      updatedChanges[existingChangeIndex] = {
        tag,
        chunk: evidence.chunk,
        field, 
        updatedTag,
        old_value: previousValues[changeKey], 
      };
      setPendingChanges(updatedChanges);
    } else {
      setPendingChanges([
        ...pendingChanges,
        {
          tag,
          chunk: evidence.chunk,
          field, 
          updatedTag,
          old_value: previousValues[changeKey], 
        },
      ]);
    }

    const result = props.evidenceData.find(
      (data) =>
        data.tag === tag &&
        data.evidences.some((e) => e.chunk === evidence.chunk),
    );
    if (result) {
      const evidenceIndex = result.evidences.findIndex(
        (e) => e.chunk === evidence.chunk,
      );
      if (evidenceIndex !== -1) {
        result.evidences[evidenceIndex] = updatedTag;
        props.setEvidenceData([...props.evidenceData]);
      }
    }
  };


  const handleCheckboxChange = (tag, evidence, field, value) => {
    setPreviousValues((prev) => ({
      ...prev,
      [`${tag}_${evidence.chunk}_${field}`]: evidence[field], // keep track of the previous state for each field individually
    }));

    handleInputChange(tag, evidence, field, value); // update the actual evidence state
  };

  const handleCollapse = (column) => {
    if (collapsedCollumns.has(column)) {
      collapsedCollumns.delete(column);
    } else {
      collapsedCollumns.add(column);
    }
    setCollapsedCollumns(new Set(collapsedCollumns));
  };

  return (
    <Modal
      isOpen={props.isEvidenceModalOpen}
      onClose={() => {
        if (pdf) return;
        props.setIsEvidenceModalOpen(false);
        props.setShowAllEvidence(false);
        props.setIsEvidenceModalOpen(false);
      }}
      title="Evidence List"
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="text-base">{props.currentItemKey}</div>
          <button onClick={handleDownload} className="download-button">
            <DownloadIcon />
          </button>
        </div>
        <table className="evidence-table">
          <thead>
            <tr>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">File Name</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("File Name")}
                    >
                      {collapsedCollumns.has("File Name") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    style={{
                      width: collapsedCollumns.has("File Name") ? 60 : "auto",
                    }}
                    onChange={(e) => setFileNameFilter(e.target.value)}
                    placeholder="Filter by File Name"
                  />
                </div>
              </th>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">Tag</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("Tag")}
                    >
                      {collapsedCollumns.has("Tag") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    style={{
                      width: collapsedCollumns.has("Tag") ? 60 : "auto",
                    }}
                    onChange={(e) => setTagFilter(e.target.value)}
                    placeholder="Filter by Tag"
                  />
                </div>
              </th>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">Chunk</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("Chunk")}
                    >
                      {collapsedCollumns.has("Chunk") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    style={{
                      width: collapsedCollumns.has("Chunk") ? 60 : "auto",
                    }}
                    onChange={(e) => setChunkFilter(e.target.value)}
                    placeholder="Filter by Chunk"
                  />
                </div>
              </th>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">Values</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("Values")}
                    >
                      {collapsedCollumns.has("Values") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    className={
                      valuesFilter
                        ? preferences?.profile?.DEFAULT_VALUE_FILTER ===
                        valuesFilter && "bg-yellow-200"
                        : ""
                    }
                    style={{
                      width: collapsedCollumns.has("Values") ? 60 : "auto",
                    }}
                    onChange={(e) => setValuesFilter(e.target.value)}
                    value={valuesFilter}
                    placeholder="Filter by Value"
                  />
                </div>
              </th>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">Evidence</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("Evidence")}
                    >
                      {collapsedCollumns.has("Evidence") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    style={{
                      width: collapsedCollumns.has("Evidence") ? 60 : "auto",
                    }}
                    onChange={(e) => setEvidenceFilter(e.target.value)}
                    placeholder="Filter by Evidence"
                  />
                </div>
              </th>
              <th>
                <div className="flex flex-col">
                  <div className="flex justify-between gap-2 items-center py-2">
                    <div className="text-nowrap">Reason</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCollapse("Reason")}
                    >
                      {collapsedCollumns.has("Reason") ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    style={{
                      width: collapsedCollumns.has("Reason") ? 60 : "auto",
                    }}
                    onChange={(e) => setReasonFilter(e.target.value)}
                    placeholder="Filter by Reason"
                  />
                </div>
              </th>
              <th className="whitespace-nowrap">Is Correct</th>
              <th className="whitespace-nowrap">Is Submitted</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(({ tag, evidence }, index) => (
              <tr key={index}>
                <td>
                  <div className="max-w-xs break-all">
                    {collapsedCollumns.has("File Name") ? (
                      <>...</>
                    ) : (
                      <>{evidence.fileName}</>
                    )}
                  </div>
                </td>
                <td>{collapsedCollumns.has("Tag") ? <>...</> : <>{tag}</>}</td>
                <td>
                  {collapsedCollumns.has("Chunk") ? (
                    <>...</>
                  ) : (
                    <>{evidence.chunk}</>
                  )}
                </td>
                <td>
                  {collapsedCollumns.has("Values") ? (
                    <>...</>
                  ) : (
                    <>
                      {evidence.isValid ? (
                        evidence.values.map((value, valueIndex) => (
                          <div key={valueIndex}>{value}</div>
                        ))
                      ) : (
                        <textarea
                          value={evidence.values?.[0]}
                          onChange={(e) => {
                            props.evidenceData
                              .find((data) => data.tag === tag)
                              .evidences.find(
                                (_evidence) =>
                                  _evidence.chunk === evidence.originalChunk,
                              ).values = [e.target.value];
                            props.setEvidenceData([...props.evidenceData]);
                          }}
                        />
                      )}
                    </>
                  )}
                </td>
                <td>
                  {collapsedCollumns.has("Evidence") ? (
                    <>...</>
                  ) : (
                    <>
                      {evidence.fileName.endsWith(".pdf") ||
                        evidence.fileName.endsWith(".docx") ? (
                        <div
                          className="bg-zinc-100 rounded-md p-2 border cursor-pointer w-full max-w-xs"
                          onClick={async () => {
                            const file = currentDataGroup[evidence.fileName];
                            const fileDirectory = file["file_directory"][0];
                            const storageName = file["storage_name"][0];
                            const storageType = file["storage_type"][0];
                            const response = await sendRequest(
                              {
                                [userProfile.system.API_USERNAME_KEYWORD]: (
                                  await Auth.currentAuthenticatedUser()
                                ).username,
                              },
                              `/api/catalog/download?file_directory=${encodeURI(fileDirectory)}&storage_type=${encodeURI(storageType)}&storage_name=${encodeURI(storageName)}&file_name=${encodeURI(evidence.fileName)}`,
                              "GET",
                            );
                            if (!response || typeof response === "string")
                              return;

                            const { data } = await response.json();

                            setPdf(data.url);
                            setPdfSearch(JSON.stringify([evidence.evidence]));
                          }}
                        >
                          {evidence.evidence}
                        </div>
                      ) : (
                        <div className="w-full max-w-xs">
                          {evidence.evidence}
                        </div>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {collapsedCollumns.has("Reason") ? (
                    <>...</>
                  ) : (
                    <div className="w-full max-w-xs">{evidence.reason}</div>
                  )}
                </td>
                <td>
                  <div className="flex justify-center h-full">
                    <input
                      type="checkbox"
                      checked={evidence.isValid}
                      onChange={(e) =>
                        handleCheckboxChange(
                          tag,
                          evidence,
                          "isValid",
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="flex justify-center h-full">
                    <input
                      type="checkbox"
                      checked={evidence.isSubmitted}
                      onChange={(e) =>
                        handleCheckboxChange(
                          tag,
                          evidence,
                          "isSubmitted",
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="save-button-container">
          <button
            className="save-button"
            disabled={isSaveDisabled}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}
