import { useContext, useEffect, useMemo } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./TagFilter.css";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faWarning,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import ProgressPie from "./PieChart";
import { toast } from "../Toast";
import { CgSpinnerTwo } from "react-icons/cg";
import { useUserProfile } from "../../../context/UserProfile";
import { runningTasksAtom, selectedCatalogItemsAtom } from "../../../atoms";
import { useAtom } from "jotai";

export default function TagFilter({
  label,
  categoryKey,
  showScreen,
  isBeingDeleted,
}) {
  const {
    deleteAllLabel,
    availableTags,
    currentDataGroup,
    hiddenCategories,
    failedTags,
    toggleCategoryVisibility,
    ruleDict,
  } = useContext(DataContext);
  const {
    relatedInfo,
    setRelatedInfo,
    processTag,
    processingTags,
    runSingleRule,
    editTag,
  } = useContext(TagContext);

  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);
  const { permissions } = useUserProfile();
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  useEffect(() => {
    const calculateRelatedInfo = () => {
      let info = {};

      Object.entries(tagDict).forEach(([key, value]) => {
        let counter = 0;
        let name = value.name;
        let matchingNames = [];

        Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
          if (!groupValue.hasOwnProperty(name)) {
            counter++;
            matchingNames.push(groupKey);
          }
        });

        info[key] = { name, counter, matchingNames };
      });

      setRelatedInfo(info);
    };

    calculateRelatedInfo();
  }, [currentDataGroup, setRelatedInfo, tagDict]);

  return (
    <div
      className={`flex relative bg-white border-b py-4 px-4 dark:border-b-zinc-600 dark:bg-zinc-800 dark:text-white`}
    >
      <div className="flex shrink-0 w-16 items-start justify-center ">
        <ProgressPie
          percentage={
            relatedInfo[categoryKey]
              ? (1 -
                  relatedInfo[categoryKey].counter /
                    Object.keys(currentDataGroup).length) *
                100
              : 0
          }
        />
      </div>

      <div
        className={`flex-1 w-full justify-between px-4 ${
          showScreen !== "catalog" && "menu-button-catalog"
        } ${
          isBeingDeleted
            ? "opacity-50 pointer-events-none"
            : "opacity-100 pointer-events-auto"
        }`}
      >
        <div>
          <div className="break-all justify-between flex-1">
            {" "}
            <span className="font-medium text-sm">{label}</span>
            {processingTags.map(({ label }) => label).includes(label) && (
              <div className="inline-block ml-1 mr-2">
                <div className="animate-spin">
                  <CgSpinnerTwo />
                </div>
              </div>
            )}
            <button
              className="toggle-visibility-button pl-1"
              onClick={(e) => toggleCategoryVisibility(e, categoryKey)}
              title={
                hiddenCategories.includes(categoryKey)
                  ? "Unhide Category"
                  : "Hide Category"
              }
            >
              {hiddenCategories.includes(categoryKey) ? (
                <EyeOffIcon className="h-5 w-5 text-buttonGrey" />
              ) : (
                <EyeIcon className="h-5 w-5 text-buttonGrey" />
              )}
            </button>
          </div>

          <div>
            <p className="py-2 text-sm">{tagDict[label]?.description}</p>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex flex-row">
              {tagDict[label]?.tagType === "rule" ? (
                <button
                  onClick={() => {
                    runSingleRule(currentDataGroup, tagDict[label].name);
                  }}
                  className={`text-xs px-2 py-1 font-medium rounded-md text-white ${
                    !relatedInfo?.[categoryKey]?.counter
                      ? "opacity-50"
                      : "opacity-100"
                  }
                      bg-primary
                  `}
                >
                  Run rule on{" "}
                  {selectedCatalogItems.size ||
                    relatedInfo[categoryKey]?.counter ||
                    0}{" "}
                  documents
                </button>
              ) : (
                <>
                  {/* <div className="flex flex-row w-full items-center">
                    {!processingTags
                      .map(({ label }) => label)
                      .includes(label) ? (
                      <button
                        onClick={() => {
                          const existingIndex = runningTasks.findIndex(
                            (task) =>
                              task.id === categoryKey && task.completed !== 1
                          );
                          const existingCompletedTask = runningTasks.find(
                            (task) =>
                              task.id === categoryKey && task.completed === 1
                          );
                          toast.success({
                            title: "Success",
                            description: `Tag queued for ${selectedCatalogItems.size || relatedInfo[categoryKey].counter} documents`,
                          });
                          if (existingIndex === -1) {
                            if (existingCompletedTask) {
                              existingCompletedTask.completed = 0;
                              setRunningTasks([...runningTasks]);
                            } else {
                              const newTask = {
                                id: categoryKey,
                                process: "Tagging",
                                description: categoryKey,
                                completed: 0,
                              };
                              setRunningTasks(() => [...runningTasks, newTask]);
                            }
                          } else {
                            setRunningTasks((prevTasks) =>
                              prevTasks.filter(
                                (task) => task.id !== categoryKey
                              )
                            );
                          }
                          return processTag("RerunTag", categoryKey);
                        }}
                        disabled={
                          runningTasks.filter((t) => t.completed !== 1).length >
                            0 || processingTags.size > 0
                        }
                        className={`rounded-md text-white p-2 font-medium items-center flex flex-row gap-2 text-xs ${
                          runningTasks.filter((t) => t.completed !== 1).length >
                            0 || processingTags.size > 0
                            ? "opacity-50"
                            : "opacity-100"
                        }

                  `}
                        title={`Tag ${
                          selectedCatalogItems.size ||
                          relatedInfo[categoryKey]?.counter ||
                          0
                        } untagged documents`}
                      >
                        <span className="text-primary text-sm">Untagged </span>
                        <span className="text-white bg-primary text-sm p-2 rounded-md">
                          {selectedCatalogItems.size ||
                            relatedInfo[categoryKey]?.counter ||
                            0}{" "}
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          abortTagging(label);
                        }}
                        disabled={!processingTagTasks.has(label)}
                        className={`text-white rounded-md text-xs font-medium p-2 mr-2 ${
                          !processingTagTasks.has(label)
                            ? "opacity-50"
                            : "opacity-100"
                        } bg-red-400`}
                      >
                        Abort tagging
                      </button>
                    )}
                    <div>
                      <button
                        className={`text-white rounded-md p-3 text-xs font-medium ${
                          processingTagTasks.has(label)
                            ? "opacity-50"
                            : "opacity-100"
                        } bg-yellow-500`}
                        title="Rerun Tag"
                        onClick={() => {
                          const existingIndex = runningTasks.findIndex(
                            (task) => task.id === categoryKey
                          );
                          toast.success({
                            title: "Success",
                            description: `Rerun tag queued for ${Object.keys(currentDataGroup || {}).length} documents`,
                          });
                          if (existingIndex === -1) {
                            const newTask = {
                              id: categoryKey,
                              process: "Tagging",
                              description: categoryKey,
                              completed: 0,
                            };
                            setRunningTasks(() => [...runningTasks, newTask]);
                          } else {
                            setRunningTasks((prevTasks) =>
                              prevTasks.filter(
                                (task) => task.id !== categoryKey
                              )
                            );
                          }
                          return processTag("RerunTag", categoryKey, true);
                        }}
                      >
                        <TfiReload />
                      </button>
                    </div>
                  </div> */}
                  <div className="flex flex-row items-center w-full justify-end">
                    {permissions.tags.canEdit &&
                      tagDict[label]?.tagType !== "rule" && (
                        <>
                          <button
                            className="button-edit-button-labels text-buttonGrey bg-white"
                            onClick={(e) => editTag(e, categoryKey)}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="text-buttonGrey"
                            />
                          </button>
                          <button
                            className="text-buttonGrey bg-whit px-2"
                            onClick={(e) => deleteAllLabel(e, categoryKey)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </>
                      )}
                  </div>
                </>
              )}
              {
                <span className="tooltip-text">
                  {relatedInfo[categoryKey]?.counter === 0
                    ? "All files are tagged"
                    : "Run tagging on all files untagged"}
                </span>
              }
            </div>
            <div className="flex">
              {failedTags.has(categoryKey) && (
                <button
                  className="tag-failed-label"
                  title={`Tag failed on ${
                    failedTags.get(categoryKey).length
                  } file(s) - Re-run`}
                  onClick={() => {
                    const existingIndex = runningTasks.findIndex(
                      (task) => task.id === categoryKey,
                    );
                    toast.success({
                      title: `Re-run of failed tag queued: ${categoryKey}`,
                      description: "",
                    });
                    if (existingIndex === -1) {
                      const newTask = {
                        id: categoryKey,
                        category: "Tagging",
                        description: categoryKey,
                        completed: 0,
                      };
                      setRunningTasks([...runningTasks, newTask]);
                    } else {
                      setRunningTasks((prevTasks) =>
                        prevTasks.filter((task) => task.id !== categoryKey),
                      );
                    }
                    processTag("ReRunFailed", categoryKey);
                  }}
                >
                  <FontAwesomeIcon icon={faWarning} />
                  <FontAwesomeIcon icon={faRedo} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
