import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchDocumentContent } from "../functions/apiCalls";
import { DataContext } from "../../../context/DataContext";

import {
  faEye,
  faFolder,
  faChevronRight,
  faChevronDown,
  faStar,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FileCheckbox } from "../FileCheckbox";

const FolderList = ({
  folders,
  currentFolder,
  checkedItems,
  setCheckedItems,
  searchText,
  setSearchText,
  setShowFilePreview,
  setFilePreviewContent,
  filteredFolderKeys,
  setCurrentFolder,
  integration,
  mode = "standard",
}) => {
  const { catalogFiles, quarantinedFiles, preferences } =
    useContext(DataContext);

  const handleCheckboxChange = (file, folderKey) => {
    setCheckedItems((prevState) => {
      const isChecked = !(prevState[file]?.isChecked ?? false);
      if (!isChecked && prevState[file]) {
        const { [file]: removedFile, ...rest } = prevState;
        return rest;
      }
      return {
        ...prevState,
        [file]: {
          isChecked,
          folder: folderKey,
          source: integration,
        },
      };
    });
  };

  const handleFolderCheckboxChange = (folderKey) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const filesInFolder = folders[folderKey] || [];
      const allChecked = filesInFolder.every(
        (file) => newCheckedItems[file]?.isChecked,
      );

      if (allChecked) {
        filesInFolder.forEach((file) => {
          if (newCheckedItems[file]) {
            delete newCheckedItems[file];
          }
        });
      } else {
        filesInFolder.forEach((file) => {
          newCheckedItems[file] = {
            isChecked: true,
            folder: folderKey,
            source: integration,
          };
        });
      }

      return newCheckedItems;
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFilePreviewClick = async (file) => {
    const filePath = `${catalogFiles[file]["file_directory"][0]}/${file}`;

    setFilePreviewContent("...Loading");
    try {
      const content = await fetchDocumentContent(
        filePath,
        preferences.webapp_profile.DATA_STORES,
      );
      setFilePreviewContent(content);
      setShowFilePreview(true);
    } catch (error) {
      console.error("Error fetching document content", error);
    }
  };

  const handleFolderClick = (folderKey) => {
    setCurrentFolder((prevFolder) =>
      prevFolder === folderKey ? null : folderKey,
    );
  };

  const renderItems = () => {
    return (
      <>
        <div className="w-full">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-2 rounded-t-md outline-none border"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex flex-col overflow-hidden pb-[7vh]">
          <ul className="w-full text-sm h-full overflow-auto">
            {filteredFolderKeys &&
              filteredFolderKeys.map((folderKey) => {
                const filesInFolder = folders[folderKey] || [];

                if (filesInFolder.length === 0) return null;

                const totalFiles = filesInFolder.length;

                const containedFiles = filesInFolder.filter(
                  (file) => catalogFiles[file] || quarantinedFiles[file],
                ).length;

                const preparedFiles = totalFiles;

                const containedFilesPercentage = Math.round(
                  (containedFiles / totalFiles) * 100,
                );
                const preparedFilesPercentage = Math.round(
                  (preparedFiles / totalFiles) * 100,
                );
                const isFolderSelected = filesInFolder.every(
                  (file) => checkedItems[file]?.isChecked,
                );
                return (
                  <li
                    key={folderKey}
                    className={`border-b px-2 ${
                      isFolderSelected ? "selected" : ""
                    } ${
                      containedFilesPercentage === 100 && mode === "standard"
                        ? "!bg-light !bg-opacity-40"
                        : "bg-slate-100"
                    }`}
                  >
                    <div
                      className={`flex gap-2 items-center cursor-pointer py-2`}
                      onClick={() => handleFolderClick(folderKey)}
                    >
                      <FontAwesomeIcon
                        icon={
                          currentFolder === folderKey
                            ? faChevronDown
                            : faChevronRight
                        }
                      />

                      <input
                        type="checkbox"
                        id={`checkbox-folder-${folderKey}`}
                        onChange={() => handleFolderCheckboxChange(folderKey)}
                        checked={filesInFolder.every(
                          (file) => checkedItems[file]?.isChecked,
                        )}
                      />
                      <div htmlFor={`checkbox-folder-${folderKey}`}>
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="pr-1 text-yellow-400"
                        />
                        {folderKey}
                      </div>
                      <span className="file-count">
                        {totalFiles} files
                        {mode !== "autoCreation" && (
                          <>
                            {" "}
                            ({containedFilesPercentage.toFixed(0)}% contained)
                          </>
                        )}
                        {mode === "autoCreation" && (
                          <> ({preparedFilesPercentage.toFixed(0)}% prepared)</>
                        )}
                      </span>
                    </div>
                    {currentFolder === folderKey && (
                      <ul className="file-tree">
                        {filesInFolder
                          .filter((file) =>
                            file
                              .toLowerCase()
                              .includes(searchText.toLowerCase()),
                          )
                          .map((file, index) => {
                            const isContained =
                              catalogFiles[file] || quarantinedFiles[file];
                            const isFileSelected =
                              checkedItems[file]?.isChecked ?? false;
                            const hasKeyQuestions =
                              catalogFiles[file]?.Key_Questions ?? false;

                            return (
                              <li
                                key={file}
                                className={`p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all ${
                                  isContained &&
                                  (mode === "standard" || mode === "tagStudio")
                                    ? "bg-light bg-opacity-30"
                                    : index % 2
                                      ? "bg-slate-50"
                                      : "bg-slate-100"
                                }`}
                              >
                                <FileCheckbox
                                  fileName={file}
                                  isSelected={
                                    checkedItems[file]?.isChecked ?? false
                                  }
                                  onClick={() =>
                                    handleCheckboxChange(file, currentFolder)
                                  }
                                />
                                <div className="flex items-center md:w-auto w-full justify-end">
                                  {mode === "autoCreation" && (
                                    <FontAwesomeIcon
                                      icon={
                                        hasKeyQuestions
                                          ? faCheckCircle
                                          : faTimesCircle
                                      }
                                      className={
                                        hasKeyQuestions
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }
                                      title={
                                        hasKeyQuestions
                                          ? "Data is prepared"
                                          : "Data is not prepared"
                                      }
                                    />
                                  )}
                                  <button
                                    onClick={() => handleFilePreviewClick(file)}
                                    className="bg-containerLight px-2 py-1 text-deasieBlack rounded-md whitespace-nowrap ml-2"
                                  >
                                    <FontAwesomeIcon icon={faEye} /> Preview
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {renderItems()}
    </div>
  );
};

export default FolderList;
