import "./DataCatalog.css";
import TagFilter from "../../../../utilities/TagFilter/TagFilter";
import { toast } from "./../../../../utilities/Toast";
import useCatalogData from "./useCatalogData";
import {useContext, useMemo, useState, useCallback, useEffect} from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  runningTasksAtom,
  selectedTagKeysAtom,
  selectedCatalogItemsAtom,
} from "../../../../../atoms";
import { useAtom } from "jotai";
import { waitTaskDone } from "../../../../../utils/workers";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { sendRequest} from "../../../../utilities/functions/api";
import Auth from "../../../../../auth/AuthProvider";

const TagDefinitions = () => {
  const {
    showScreen,
    catalogSummary,
    availableTags,
    selectedFilters,
    preferences,
    failedTags,
    tagsToBeDeleted,
    ruleDict,
    currentDataGroup,
    usedCatalog,
  } = useContext(DataContext);
  const {
    processTag,
    relatedInfo,
    setProcessingTags,
    processingTags,
    setProcessingTagTasks,
    autoStandardizeTagsWithoutAvailableValues
  } = useContext(TagContext);

  const [selectedTagKeys, setSelectedTagKeys] = useAtom(selectedTagKeysAtom);
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const {
    handleFilterChange,
    standardizeValues,
    handleResetFilter,
    dropdownOpen,
    setDropdownOpen,
    noOfDocumentsWithFailedTags,
  } = useCatalogData();

  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);

  const [searchTerm, handleSearchChange] = useState("");

  const tagsShown = useMemo(() => {
    return Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    }).filter((key) => {
      return searchTerm
        ? key.toLowerCase().includes(searchTerm.toLowerCase())
        : key !== "file_directory" &&
            !Object.keys(preferences.system.SENSITIVITY_TAGS).includes(key) &&
            !preferences.system.EXCLUDE_TAGS.includes(key);
    });
  }, [
    availableTags.llm.tagger_params.tag_dict,
    ruleDict,
    searchTerm,
    preferences.system.SENSITIVITY_TAGS,
    preferences.system.EXCLUDE_TAGS,
  ]);

  const failedTagsShown = useMemo(() => {
    return [...failedTags].filter((tag) => {
      return tagsShown.includes(tag?.[0] || "");
    });
  }, [failedTags, tagsShown]);

  const totalUntaggedDocuments = useMemo(() => {
    let total = 0;

    Object.entries(relatedInfo).forEach(([key, info]) => {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(key)) {
        total += info.counter;
      }
    });

    return total;
  }, [relatedInfo, selectedTagKeys]);

  const runAllTags = useCallback(async () => {
    const taskId = `runAllTags-${Date.now()}`;
    const entries = [];

    // Deep copy to avoid mutations
    const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));

    for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
      {}) {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey))
        continue;
      delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
    }

    const dataSnapShot = { ...currentDataGroup };

    // Add new tasks to the processing tags for each tag
    const allTags = Object.keys(
      availableTags.llm.tagger_params.tag_dict,
    ).filter((tagKey) => {
      return selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey);
    });
    setProcessingTags((prev) => [
      ...prev,
      ...allTags.map((tag) => ({ label: tag })),
    ]);

    const selectedFiles = Array.from(selectedCatalogItems);

    // Iterate through each file and prepare entries
    for (const file_name of Object.keys(dataSnapShot)) {
      if (selectedFiles.length === 0 || selectedFiles.includes(file_name)) {
        const catalogItem = dataSnapShot[file_name];
        const sendChunkObject = {
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              catalogItem.data_store_name
                ? catalogItem.data_store_name
                : catalogItem.storage_type
            ],
            path: `${catalogItem.file_directory}/${file_name}`,
          }),
          tagger_list: JSON.stringify(availableTagsCopy),
          file_catalog_entry: JSON.stringify({ [file_name]: {} }),
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          check_sensitivity: false,
        };

        entries.push(sendChunkObject);
      }
    }

    // Ensure state updates before async operations
    setRunningTasks((tasks) => [
      ...tasks,
      {
        id: taskId,
        process: "Tagging",
        description: "Running all tags",
        completed: 0,
      },
    ]);

    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const res = await sendRequest(
        {
          entries,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          preferences: JSON.stringify(preferences),
        },
        ENDPOINTS["create_catalog_in_bulk"],
      );
      const { task_id } = await res.json();

      setRunningTasks((tasks) => {
        const updatedTask = tasks.find((task) => task.id === taskId);
        if (updatedTask) {
          updatedTask.id = task_id;
        }
        return [...tasks];
      });

      setProcessingTagTasks((prev) => {
        const newMap = new Map(prev);
        allTags.forEach((tag) => {
          newMap.set(tag, [...(newMap.get(tag) || []), task_id]);
        });
        return newMap;
      });

      // Monitor task progress
      waitTaskDone(task_id, creds, undefined, ({ completed }) => {
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === task_id);
          if (updatedTask) {
            updatedTask.completed = completed;
          }
          return [...tasks];
        });
      }).then(() => {
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === task_id);
          if (updatedTask) {
            updatedTask.completed = 1;
          }
          return [...tasks];
        });

        setProcessingTags((prev) =>
          prev.filter(({ label }) => !allTags.includes(label)),
        );
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          allTags.forEach((tag) => {
            newMap.delete(tag);
          });
          return newMap;
        });
        autoStandardizeTagsWithoutAvailableValues();
        toast.success({
          title: "Success",
          description: "All tags have been processed successfully.",
        });
      });
    } catch (error) {
      console.error("Error running all tags:", error);
      toast.error({
        title: "Error",
        description: "There was an issue processing the tags.",
      });

      setProcessingTags((prev) =>
        prev.filter(({ label }) => !allTags.includes(label)),
      );
      setProcessingTagTasks((prev) => {
        const newMap = new Map(prev);
        allTags.forEach((tag) => {
          newMap.delete(tag);
        });
        return newMap;
      });
    }
  }, [
    availableTags,
    currentDataGroup,
    setProcessingTags,
    setRunningTasks,
    selectedTagKeys,
    preferences.webapp_profile.DATA_STORES,
    preferences.system.QUARANTINECATALOG,
    preferences.system.API_USERNAME_KEYWORD,
    usedCatalog,
    setProcessingTagTasks,
  ]);

  const abortTagging = useCallback(
    async (taskId) => {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      try {
        await sendRequest(
          {
            task_id: taskId,
            [preferences.system.API_USERNAME_KEYWORD]: creds,
          },
          ENDPOINTS.revoke_task,
        );
        return true;
      } catch (error) {
        console.error("Error aborting the task:", error);
        return false;
      }
    },
    [preferences],
  );

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  const abortAllTags = useCallback(async () => {
    const allTags = Object.keys(availableTags.llm.tagger_params.tag_dict);
    const task = runningTasks.find(
      (task) => task.description === "Running all tags" && task.completed !== 1,
    );

    if (task) {
      try {
        const success = await abortTagging(task.id);
        if (success) {
          setRunningTasks((tasks) => tasks.filter((t) => t.id !== task.id));
          setProcessingTags((prev) =>
            prev.filter(({ label }) => !allTags.includes(label)),
          );
          setProcessingTagTasks((prev) => {
            const newMap = new Map(prev);
            allTags.forEach((tag) => {
              newMap.delete(tag);
            });
            return newMap;
          });
          toast.info({
            title: "Process Stopped",
            description: "The tagging process has been successfully stopped.",
          });
        } else {
          toast.error({
            title: "Error",
            description: "Failed to stop the tagging process.",
          });
        }
      } catch (error) {
        console.error("Error aborting all tags:", error);
        toast.error({
          title: "Error",
          description: "Failed to stop the tagging process.",
        });
      }
    } else {
      toast.warning({
        title: "Warning",
        description: "No active tagging process found to stop.",
      });
    }
  }, [
    runningTasks,
    availableTags,
    setRunningTasks,
    setProcessingTags,
    setProcessingTagTasks,
    abortTagging,
  ]);

  return (
    <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden">
      <div className="p-3 flex items-center shrink-0 bg-slate-200 dark:bg-zinc-600 dark:text-white">
        <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3 "></i>
        <div className="text-lg font-bold">Available Tags</div>
      </div>
      <input
        type="text"
        placeholder="Search Tags..."
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        className="search-bar p-4 text-sm outline-none w-full border"
      />

      <button
        className="bg-white rounded-md text-primary border-2 border-primary p-2 m-6"
        onClick={() => {
          if (selectedTagKeys.length) {
            return setSelectedTagKeys([]);
          }
          setSelectedTagKeys(
            Object.keys(availableTags?.llm?.tagger_params?.tag_dict || {}),
          );
        }}
      >
        {selectedTagKeys.length > 0 ? "Deselect all" : "Select all"}
      </button>
      <div className="flex flex-col overflow-y-auto overflow-x-hidden h-full ">
        {tagsShown
          .sort((tagAKey, tagBKey) => {
            const tagA = tagDict[tagAKey];
            const tagB = tagDict[tagBKey];

            if (!tagA || !tagB) {
              return 0;
            }

            if (!tagA.updated_at) {
              tagA.updated_at = new Date("1976/01/01").toISOString();
            }
            if (!tagB.updated_at) {
              tagB.updated_at = new Date("1976/01/01").toISOString();
            }

            return (
              new Date(tagB.updated_at).getTime() -
              new Date(tagA.updated_at).getTime()
            );
          })
          .map((key) => {
            return (
              <div
                className="relative cursor-pointer"
                onClick={() => {
                  if (!selectedTagKeys.includes(key)) {
                    setSelectedTagKeys((prev) => [...prev, key]);
                  } else {
                    setSelectedTagKeys((prev) =>
                      prev.filter((_key) => _key !== key)
                    );
                  }
                }}
              >
                {selectedTagKeys.includes(key) && (
                  <div className="inset-0 bg-green-400 bg-opacity-10 absolute z-50 pointer-events-none"></div>
                )}
                <div className="px-2 bg-white">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTagKeys((prev) => [...prev, key]);
                      } else {
                        setSelectedTagKeys((prev) =>
                          prev.filter((_key) => _key !== key)
                        );
                      }
                    }}
                    checked={selectedTagKeys.includes(key)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <TagFilter
                  key={key}
                  label={key}
                  categoryKey={key}
                  options={catalogSummary[key]?.availableValues || []}
                  onFilterChange={handleFilterChange}
                  selectedOptions={selectedFilters[key]}
                  handleReset={handleResetFilter}
                  showScreen={showScreen}
                  standardizeValues={standardizeValues}
                  catalogSummary={catalogSummary}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  isBeingDeleted={tagsToBeDeleted.includes(key)}
                ></TagFilter>
              </div>
            );
          })}
      </div>
      <div className="bg-slate-200 dark:bg-zinc-600 flex flex-col w-full">
        <div className="flex flex-row w-full justify-between">
          {selectedTagKeys.length > 0 && (
            <div className="bg-slate-100 border w-full justify-between flex flex-row items-center">
              {processingTags.length > 0 ? (
                <button
                  className="py-3 px-6 flex flex-row items-center bg-slate-100 justify-end"
                  onClick={abortAllTags}
                >
                  <p className="text-md bg-red-400 text-white p-2 rounded-md font-bold">
                    Abort All
                  </p>
                </button>
              ) : (
                <button
                  className="py-3 px-6 justify-between flex flex-row items-center w-full"
                  onClick={runAllTags}
                >
                  <p className="bg-primary  text-white p-3 rounded-md text-md">
                    {selectedTagKeys.length > 0
                      ? `Run ${selectedTagKeys.length} ${selectedTagKeys.length > 1 ? "Tags" : "Tag"}`
                      : "Tag"}
                  </p>
                  <p className="text-white bg-primary text-md p-2 rounded-md">
                    {totalUntaggedDocuments}
                  </p>
                </button>
              )}
              <div className=" hidden group-hover:block absolute -top-12 -left-18 w-48 bg-gray-800 text-white p-2 rounded-md z-99">
                The right number shows you how many datasets have not been
                tagged with selcted tags
              </div>
            </div>
          )}
        </div>
        {failedTagsShown.length > 0 && (
          <button
            className="bg-yellow-500 px-4 py-2 text-white rounded-md text-sm"
            title={`${failedTagsShown.length} Tag(s) failed on ${noOfDocumentsWithFailedTags} file(s) - Re-run all`}
            onClick={() => {
              failedTagsShown.forEach((_, failedTag) => {
                processTag("ReRunFailed", failedTag);
              });

              toast.success({
                title: `Re-run of all failed tags queued: ${failedTagsShown.length}`,
                description: "",
              });
            }}
          >
            Re-run failed tags: {failedTagsShown.length}
          </button>
        )}
      </div>
    </div>
  );
};

export default TagDefinitions;
