import { useContext, useMemo, useState } from "react";
import { useCatalogNames } from "../../../api/queryHooks";
import { DataContext } from "../../../context/DataContext";
import { useUserProfile } from "../../../context/UserProfile";
import {
  FaChartBar,
  FaFilter,
  FaPencilAlt,
  FaPlusCircle,
  FaTable,
} from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast } from "../Toast";
import useCatalogData from "../../pages/Home/HomeComponents/DataCatalog/useCatalogData";
import DataGraph from "../../pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/DataGraph/DataGraph";
import { useCatalogContext } from "../../../context/CatalogContext";

const styleForButton =
  "ml-4 px-4 py-2 text-center rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-300 ease-in-out text-primary border-2 border-primary font-bold whitespace-nowrap items-center flex gap-1";

export default function SearchBar() {
  const {
    setSearchTerm,
    usedCatalog,
    handleCatalogChange,
    handleCatalogRename,
    handleEvidenceButtonClick,
    handleMultipleDelete,
    setShowScreen,
    setCurrentTag,
    currentDataGroup,
    selectedFilters,
    setView,
    setShowConnectData,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();
  const { quarantineFiles } = useCatalogData();
  const { isLoading, data: catalogNames = [], refetch } = useCatalogNames();
  const [isCatalogNameBeingEdited, setIsCatalogNameBeingEdited] =
    useState(false);
  const [oldCatalogName, setOldCatalogName] = useState("");
  const [newCatalogName, setNewCatalogName] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [showAddOptions] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);

  const selectValue = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  const { setShowAdvancedTagFilters } = useCatalogContext();

  return (
    <div className="w-full flex flex-col rounded-t-md bg-white">
      {showDistributionModal && (
        <div
          onClick={() => {
            setShowDistributionModal(false);
          }}
          className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-[9999] p-4"
        >
          <DataGraph
            selectedFilters={selectedFilters}
            onClose={() => setShowDistributionModal(false)}
          />
        </div>
      )}
      <div className="flex w-full p-5 items-center">
        <div className="flex flex-row w-full">
          <div className="relative">
            {isLoading || isRenaming ? (
              <div className="  px-2 py-1 rounded-md">Loading...</div>
            ) : isCatalogNameBeingEdited ? (
              <input
                className="p-2 ml-2 rounded-md border outline-none text-black"
                value={newCatalogName}
                onChange={(e) => setNewCatalogName(e.target.value)}
              />
            ) : (
              <select
                onChange={handleCatalogChange}
                value={selectValue}
                className="p-2 font-bold  rounded-md focus:outline-none text-primary border-2 border-primary"
              >
                <option value="" disabled>
                  Select a catalog
                </option>
                {catalogNames.map((catalog, index) => (
                  <option key={index} value={catalog}>
                    {catalog} {}
                  </option>
                ))}
                {permissions.catalogs.canEdit && (
                  <option value="newCatalog">Create New Catalog</option>
                )}
              </select>
            )}

            {isCatalogNameBeingEdited && (
              <div className="flex items-center">
                <button
                  onClick={() => {
                    toast.info({
                      title: "Info",
                      description: "Your catalog is being renamed, please wait",
                    });
                    setIsRenaming(true);
                    handleCatalogRename(oldCatalogName, newCatalogName)
                      .then(async () => {
                        await refetch();
                        toast.success({
                          title: "Info",
                          description: "Your catalog was successfully renamed!",
                        });
                      })
                      .finally(() => {
                        setIsRenaming(false);
                      });
                    setIsCatalogNameBeingEdited(false);
                    setOldCatalogName("");
                    setNewCatalogName("");
                  }}
                  className=" bg-deasieTurquoise p-2 rounded-md"
                >
                  <MdCheck />
                </button>
                <button
                  onClick={() => {
                    setIsCatalogNameBeingEdited(false);
                    setOldCatalogName("");
                    setNewCatalogName("");
                  }}
                  className=" bg-rose-500 p-2 rounded-md"
                >
                  <RxCross2 />
                </button>
              </div>
            )}
          </div>
          {permissions.catalogs.canEdit && !isCatalogNameBeingEdited && (
            <div className="h-full flex flex-row items-center gap-3 align-middle justify-center">
              <button
                className="p-2 text-deasieBlack"
                onClick={() => {
                  setIsCatalogNameBeingEdited(true);
                  setOldCatalogName(selectValue);
                  setNewCatalogName(selectValue);
                }}
                title="Rename Catalog"
              >
                <FaPencilAlt />
              </button>
              <div className="flex flex-row items-center border-2 rounded-md px-2">
                <p>
                  <span className="font-bold text-primary">
                    {currentDataGroup
                      ? Object.keys(currentDataGroup).length
                      : 0}
                  </span>
                  <span className="text-primary"> datasets</span>
                </p>
                <button
                  className="p-2 text-buttonGrey"
                  onClick={handleMultipleDelete}
                  title="Delete multiple datasets"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button
                  className="p-2 text-buttonGrey"
                  onClick={() => {
                    const confirmation = window.confirm(
                      `Are you sure you want to quarantine ${Object.keys(currentDataGroup || {}).length} files?`
                    );
                    if (!confirmation) return;
                    quarantineFiles();
                  }}
                  title="Quarantine multiple datasets"
                >
                  <FontAwesomeIcon icon={faLock} />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full justify-end">
          <div className="dropdown relative">
            <div
              className={`${styleForButton} bg-primary text-white cursor-pointer`}
              //onClick={() => setShowAddOptions((prevState) => !prevState)}
              onClick={() => {
                setShowScreen("addNewTag");
                setCurrentTag({});
              }}
            >
              New Tag
            </div>
            <div
              className={`transition-all duration-300 ease-in-out ${
                showAddOptions
                  ? "opacity-100 transform translate-y-0 "
                  : "opacity-0 transform -translate-y-4 pointer-events-none"
              } absolute mt-2 z-50 bg-white shadow-lg rounded w-full`}
            >
              <a
                className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setShowScreen("addNewTag");
                  setCurrentTag({});
                }}
              >
                Manual
              </a>
              {permissions.tags.canEdit && (
                <a
                  className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setShowScreen("autoCreateTag");
                  }}
                >
                  Auto create
                </a>
              )}
            </div>
          </div>
          <button
            className={styleForButton}
            onClick={() => {
              setView("options");
              setShowConnectData(true);
            }}
            title="Add Data"
          >
            Add Data
          </button>
          <button
            className={styleForButton}
            onClick={() => setShowAdvancedTagFilters(true)}
            title="Filter Tags"
          >
            <FaFilter />
          </button>
          <button
            onClick={() =>
              handleEvidenceButtonClick(Object.keys(currentDataGroup || {}))
            }
            className={styleForButton}
          >
            <FaTable />
          </button>
          <button
            onClick={() => {
              setShowDistributionModal(true);
            }}
            className={styleForButton}
            title="Distribution"
          >
            <FaChartBar />
          </button>
        </div>
      </div>
      <div className="flex items-center bg-white dark:bg-secondary rounded-lg p-4 shadow-md hover:shadow-xl m-2 ">
        <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3 "></i>
        <input
          type="text"
          className="block w-full bg-transparent focus:outline-none"
          placeholder="Search for a document"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
