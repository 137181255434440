import React, { useContext } from "react";
import { LABELS_TO_EXCLUDE_FOR_FRONTEND } from "../../../constants/labelConfig";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import { DataContext } from "../../../context/DataContext";
import "./FilterComponent.css";

export default function FilterComponent(props) {
  const { catalogSummary, availableTags, clearAllFilters } =
    useContext(DataContext);
  return (
    <div className="p-4 bg-slate-100 border overflow-x-auto">
      <div className="flex gap-4">
        <div
          onClick={clearAllFilters}
          className="text-sm whitespace-nowrap flex items-center bg-white text-slate-700 rounded-md cursor-pointer px-4 shadow-md"
        >
          <div>Clear all filters</div>
        </div>
        {availableTags &&
          Object.keys({ ...availableTags.llm.tagger_params.tag_dict })
            .sort()
            .map(
              (key) =>
                !LABELS_TO_EXCLUDE_FOR_FRONTEND.includes(key) &&
                catalogSummary[key]?.availableValues && (
                  <div key={key}>
                    <DropDownMenu
                      key={key}
                      label={key}
                      categoryKey={key}
                      handleResetFilter={props.handleResetFilter}
                      options={
                        Object.keys(catalogSummary).includes(key)
                          ? catalogSummary[key].availableValues
                          : []
                      }
                      onFilterChange={props.handleFilterChange}
                      selectedOptions={
                        props.selectedFilters && props.selectedFilters[key]
                      }
                      showScreen={props.showScreen}
                    />
                  </div>
                )
            )}
      </div>
    </div>
  );
}
