import { useState, useContext, useEffect } from "react";
import Auth from "../../../../../auth/AuthProvider";
import { DataContext } from "../../../../../context/DataContext";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import { debounce } from "lodash";
import InfoIcon from "../../../../utilities/InfoIcon/InfoIcon";
import { useCatalogNames } from "../../../../../api/queryHooks";
import { TagContext } from "../../../../../context/TagContext";
import ScoreCard from "./ScoreCard";

export const NonTaskProgressBar = ({ progress }) => {
  return (
    <div className="w-full bg-gray-200 rounded-full h-8 mb-4 relative overflow-hidden shadow-lg">
      <div
        className="bg-deasieTurquoise h-full rounded-full animate-pulse transition-all duration-500 ease-out"
        style={{ width: `${progress}%` }}
      >
        <div
          className="text-white absolute left-0 top-0 h-full bg-gradient-to-r from-transparent via-white to-transparent opacity-50 w-12 animate-slide"
          style={{ transform: `translateX(${progress}%)` }}
        ></div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <span className="text-white font-bold">{progress}%</span>
      </div>
    </div>
  );
};

export default function AutoCreateTag() {
  const {
    preferences,
    handleCatalogChange,
    usedCatalog,
    setAvailableTags,
    catalogFiles,
  } = useContext(DataContext);

  const [documents, setDocuments] = useState({});
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [integration, setIntegration] = useState(
    Object.keys(preferences.webapp_profile.DATA_STORES)[0],
  );
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [filteredFolderKeys, setFilteredFolderKeys] = useState([]);
  const [progress, setProgress] = useState(0);
  const [filterOption, setFilterOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [evaluatingTag, setEvaluatingTag] = useState(null);

  const { data: catalogNames = [] } = useCatalogNames();

  const {
    autoCreatedTags,
    setAutoCreatedTags,
    evaluatedScores,
    setEvaluatedScores,
  } = useContext(TagContext);

  const [selectedCatalog, setSelectedCatalog] = useState(usedCatalog);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedTags, setSelectedTags] = useState(new Set());
  const [selectAllTags, setSelectAllTags] = useState(false);

  const selectAll = () => {
    setSelectAllTags((prevState) => !prevState);
    const currentState = !selectAllTags;
    if (currentState) {
      const allTags = new Set(autoCreatedTags.map((tag) => tag.name));
      setSelectedTags(allTags);
    } else {
      setSelectedTags(new Set());
    }
  };

  const toggleTag = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (newSelectedTags.has(tag)) {
        newSelectedTags.delete(tag);
      } else {
        newSelectedTags.add(tag);
      }
      return newSelectedTags;
    });
  };

  useEffect(() => {
    const getDocuments = async () => {
      await fetchFolders();
    };

    getDocuments();
  }, [integration]);

  useEffect(() => {
    if (!isLoading) return;

    const checkProgress = () => {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;
      let preparedItems = 0;

      selectedFiles.forEach((file) => {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }
      });

      setProgress(Math.round((preparedItems / totalItems) * 90));
    };

    const interval = setInterval(checkProgress, 1000);

    return () => clearInterval(interval);
  }, [isLoading, checkedItems, catalogFiles]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const folderKeys = Object.keys(documents);
      setFilteredFolderKeys(
        folderKeys.filter(
          (folderKey) =>
            folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
            documents[folderKey].some((file) =>
              file.toLowerCase().includes(searchText.toLowerCase()),
            ),
        ),
      );
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, documents]);

  const PreviewModal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close-button hover:bg-primary m-3"
          >
            &times;
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    );
  };

  const fetchFolders = async () => {
    let dataStore = preferences.webapp_profile.DATA_STORES[integration];

    try {
      const requestParams = {
        data_store: JSON.stringify(dataStore),
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
      };

      const folderResponse = await sendRequest(
        requestParams,
        ENDPOINTS["fetch_folders"],
      );
      const retrievedFolders = await folderResponse.json();
      setDocuments(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    }
  };

  const handleEvaluateTags = async (tags) => {
    try {
      const username = (await Auth.currentAuthenticatedUser()).username;

      for (let tag of tags) {
        setEvaluatingTag(tag.name);

        const tagToRun = {
          ...autoCreatedTags.find((autoTag) => autoTag.name === tag.name),
        };

        const requestParams = {
          auto_created_tags: [tagToRun],
          [preferences.system.API_USERNAME_KEYWORD]: username,
          key_questions: Object.values(catalogFiles)
            .map((file) => file.Key_Questions || [])
            .flat(),
        };

        const evaluateTagsResponse = await sendRequest(
          requestParams,
          ENDPOINTS["evaluate_tags"],
        );

        if (!evaluateTagsResponse) {
          console.error("No response received for tag:", tag.name);
          continue;
        }

        const rawEvaluatedTagData = await evaluateTagsResponse.json();
        const evaluatedTagData = rawEvaluatedTagData.evaluated_tags[0];

        setEvaluatedScores((prevScores) => ({
          ...prevScores,
          [evaluatedTagData.name]: evaluatedTagData.score,
        }));

        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      setEvaluatingTag(null);
    } catch (error) {
      console.log("Error during the request", error);
    }
  };

  const handleGenerateTags = async () => {
    setAutoCreatedTags([]);
    setEvaluatedScores({});
    setIsLoading(true);
    setProgress(0);

    try {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;

      let preparedItems = 0;
      const updateProgress = (increment) => {
        setProgress((prevProgress) => prevProgress + increment);
      };

      for (const file of selectedFiles) {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }

        await new Promise((resolve) => setTimeout(resolve, 100));
        updateProgress(Math.round(preparedItems / totalItems));
      }

      const requestParams = {
        file_names: selectedFiles,
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: usedCatalog,
      };

      const autoCreateTagResponse = await sendRequest(
        requestParams,
        ENDPOINTS["auto_create_tags"],
      );

      const createdTags = await autoCreateTagResponse.json();
      const autoTags = createdTags.auto_created_tags;

      setAutoCreatedTags(autoTags);
      setProgress(100);
      setIsLoading(false);
    } catch (error) {
      console.log("Error during the request", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const evaluateTags = async () => {
      if (isLoading && autoCreatedTags.length > 0) {
        await handleEvaluateTags(autoCreatedTags);
      }
    };

    if (isLoading && autoCreatedTags.length > 0) {
      evaluateTags();
    }
  }, [autoCreatedTags, isLoading]);

  const sortTags = (tags) => {
    if (sortOption === "score") {
      return tags.sort((a, b) => {
        const aScore = evaluatedScores[a.name] || 0;
        const bScore = evaluatedScores[b.name] || 0;
        return bScore - aScore;
      });
    } else if (sortOption === "name") {
      return tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    return tags;
  };

  const closePreviewModal = () => {
    setShowFilePreview(false);
  };

  const handleConfirmSelection = () => {
    setAvailableTags((prevAvailableTags) => {
      const updatedTagDict = {
        ...prevAvailableTags.llm.tagger_params.tag_dict,
      };
      selectedTags.forEach((tagName) => {
        const tagDetails = autoCreatedTags.find((tag) => tag.name === tagName);
        if (tagDetails) {
          updatedTagDict[tagName] = { ...tagDetails };
        }
      });
      return {
        ...prevAvailableTags,
        llm: {
          ...prevAvailableTags.llm,
          tagger_params: {
            ...prevAvailableTags.llm.tagger_params,
            tag_dict: updatedTagDict,
          },
        },
      };
    });
    alert(`Confirmed ${selectedTags.size} tags`);
  };

  const filterMapping = {
    "Defined Values": "custom",
    "Yes No": "yesNo",
    "Open ended tags": "aiGenerated",
  };

  const filteredTags = autoCreatedTags.filter(
    (tag) => filterOption === "" || tag.option === filterMapping[filterOption],
  );

  return (
    <div className="flex bg-white h-full w-full justify-between flex-row p-5 rounded-md gap-5">
      <div className="flex flex-col w-2/5 gap-2 relative">
        <header className="p-2 text-gray-700 font-bold text-lg">
          Select data to create tags from{" "}
          <InfoIcon
            infoText="Deasie is distilling your datasets to create meaningful tags. Select one or more datasets to auto-create Deasie tags.
          If data is prepared, you can generate Deasie tags at a lower latency."
          />
        </header>
        <div className="flex flex-row gap-3">
          <div className="flex gap-2 items-center">
            <label className="text-md font-bold">Source</label>
            <select
              className="border-2 border-deasieBlack rounded-md px-2 py-1"
              onChange={(e) => setIntegration(e.target.value)}
              value={integration}
            >
              {Object.keys(preferences.webapp_profile.DATA_STORES).map(
                (key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                },
              )}
            </select>
          </div>
          <div className="flex gap-2 items-center">
            <h2 className="font-bold">Catalog</h2>
            <select
              className="border-2 border-deasieBlack rounded-md px-2 py-1"
              onChange={(e) => {
                setSelectedCatalog(e.target.value);
                handleCatalogChange(e);
              }}
              value={selectedCatalog}
            >
              <option value="" disabled>
                Select a catalog
              </option>
              {catalogNames.map((catalog, index) => (
                <option key={index} value={catalog}>
                  {catalog}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full flex justify-center items-center overflow-y-auto">
          <FolderList
            folders={documents}
            currentFolder={currentFolder}
            checkedItems={checkedItems}
            searchText={searchText}
            setCurrentFolder={setCurrentFolder}
            setCheckedItems={setCheckedItems}
            integration={integration}
            setFilePreviewContent={setFilePreviewContent}
            setShowFilePreview={setShowFilePreview}
            filteredFolderKeys={filteredFolderKeys}
            setSearchText={setSearchText}
          />
        </div>
        <PreviewModal
          isOpen={showFilePreview}
          onClose={closePreviewModal}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: filePreviewContent,
              }}
            />
          }
        />
        <div className="absolute bottom-5 flex p-2 w-full justify-start items-start z-100">
          <button
            onClick={async () => {
              await handleGenerateTags();
            }}
            className={`${
              autoCreatedTags && Object.keys(autoCreatedTags).length !== 0
                ? "text-primary border-2 border-primary bg-white hover:font-bold"
                : "text-white"
            } p-3 w-[50%] flex flex-col rounded-md items-center justify-start  text-xl  transition-all duration-300 ease-in-out ${
              Object.keys(checkedItems).length !== 0
                ? "bg-primary hover:font-bold"
                : "bg-slate-300"
            }`}
            disabled={Object.keys(checkedItems).length === 0}
          >
            {Object.keys(checkedItems).length === 0 ? (
              "Select one or more datasets"
            ) : isLoading ? (
              <p>...Loading</p>
            ) : (
              "Generate Tags"
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-col w-3/5 overflow-y-hidden">
        <div className="flex flex-row w-full justify-between items-center">
          <header className="p-2 text-gray-700 font-bold text-lg">
            Auto-generated tags{" "}
            <InfoIcon
              infoText={
                autoCreatedTags && Object.keys(autoCreatedTags).length > 0
                  ? "Choose tags to be added to your tag library"
                  : "Please choose datasets to generate your custom tags"
              }
            />
          </header>

          {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
            <button
              onClick={selectAll}
              className="font-bold text-md text-primary border-2 border-primary text-gray-800 px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              {selectAllTags ? "Deselect All" : "Select All"}
            </button>
          )}
        </div>

        {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
          <div className="flex flex-row gap-4 items-center mb-4">
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Filter by Option:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setFilterOption(e.target.value)}
                value={filterOption}
              >
                <option value="">All</option>
                {Object.keys(filterMapping).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Sort by:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setSortOption(e.target.value)}
                value={sortOption}
              >
                <option value="">None</option>
                <option value="score">Weighted Score</option>
                <option value="name">Tag Name</option>
              </select>
            </div>
            {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
              <button
                onClick={() => handleEvaluateTags(filteredTags)}
                className="font-bold text-md text-white bg-primary border-2 border-primary px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
              >
                Evaluate Tags
              </button>
            )}
          </div>
        )}

        <div className="flex flex-col overflow-y-auto w-full no-scrollbar">
          {!isLoading &&
            sortTags(filteredTags).map((tag, index) => (
              <div
                key={`tag-${index}`}
                className={`transition hide-scrollbar duration-500 ease-in-out p-4 rounded-lg shadow-md hover:shadow-lg mb-4 cursor-pointer ${
                  selectedTags.has(tag.name)
                    ? "border-primary border-4"
                    : "bg-gray-50 text-gray-800 hover:bg-gray-100"
                }`}
                onClick={() => toggleTag(tag.name)}
              >
                <div className="flex justify-start items-center mb-4 w-full">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {tag.name}
                    </h3>
                    <p className="text-gray-600">{tag.description}</p>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between whitespace-nowrap">
                  {tag.option !== "aiGenerated" && (
                    <div className="mt-2">
                      <h4 className="text-sm font-medium text-gray-700">
                        Available Values:
                      </h4>
                      <ul className="list-disc list-inside">
                        {tag.availableValues.map((value, valueIndex) => (
                          <li key={valueIndex} className="text-gray-600">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="text-center mt-4 flex flex-col justify-start w-full items-end space-y-2">
                    <ScoreCard
                      tag={tag}
                      evaluatingTag={evaluatingTag}
                      evaluatedScores={evaluatedScores}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        {filteredTags && filteredTags.length > 0 && !isLoading ? (
          <div className="flex flex-row w-full justify-end pb-8 pt-2">
            <button
              onClick={handleConfirmSelection}
              className={`flex flex-col text-white border-2 border-primary bg-primary px-4 py-2 rounded-md text-xl hover:bg-primary-dark transition-all duration-300 ease-in-out ${
                selectedTags.size === 0 && "opacity-50 cursor-not-allowed"
              }`}
              disabled={selectedTags.size === 0}
            >
              Add Selected Tags ({selectedTags.size})
            </button>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            {isLoading && <NonTaskProgressBar progress={progress} />}
          </div>
        )}
      </div>
    </div>
  );
}
