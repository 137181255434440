import React from "react";
import { CustomTooltipProps, ChartDataEntry, TokenModelCost } from "./types";
export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    const formatCurrency = (value: any) => {
      let numberValue = Number(value);

      if (Number.isFinite(numberValue)) {
        return `$${numberValue.toFixed(2)}`;
      } else {
        console.error(
          "Expected a number or a numeric string, but received:",
          value,
        );
        return `$0.00`;
      }
    };

    const formatNumber = (value: any) => {
      let numberValue = Number(value);

      if (Number.isFinite(numberValue)) {
        return numberValue.toLocaleString();
      } else {
        console.error(
          "Expected a number or a numeric string, but received:",
          value,
        );
        return `0`;
      }
    };

    const totals = data.models.reduce(
      (acc: ChartDataEntry, model: TokenModelCost) => {
        acc.promptTokens += Number(model.prompt_tokens);
        acc.completionTokens += Number(model.completion_tokens);
        acc.totalTokens += Number(model.total_tokens);
        acc.totalCost += Number(model.cost);
        return acc;
      },
      { promptTokens: 0, completionTokens: 0, totalTokens: 0, totalCost: 0 },
    );

    return (
      <div className="bg-white p-2 border border-gray-300 shadow-lg rounded">
        <p className="font-medium text-base">{`Date: ${label}`}</p>
        <table className="min-w-full divide-y divide-gray-200 mt-2">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Model
              </th>
              <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Prompt Tokens
              </th>
              <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Completion Tokens
              </th>
              <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total Tokens
              </th>
              <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.models.map((model: TokenModelCost, index: number) => (
              <tr key={index}>
                <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                  {model.name}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                  {formatNumber(model.prompt_tokens)}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                  {formatNumber(model.completion_tokens)}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                  {formatNumber(model.total_tokens)}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                  {formatCurrency(model.cost)}
                </td>
              </tr>
            ))}
            <tr className="bg-gray-100">
              <td className="px-2 py-1 font-medium text-gray-800">Totals</td>
              <td className="px-2 py-1 text-right text-gray-800">
                {formatNumber(totals.promptTokens)}
              </td>
              <td className="px-2 py-1 text-right text-gray-800">
                {formatNumber(totals.completionTokens)}
              </td>
              <td className="px-2 py-1 text-right text-gray-800">
                {formatNumber(totals.totalTokens)}
              </td>
              <td className="px-2 py-1 text-right text-gray-800">
                {formatCurrency(totals.totalCost)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};
