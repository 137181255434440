import { useState, useRef, useEffect, useContext } from "react";
import "./DataList.css";
import Auth from "../../../../../../../auth/AuthProvider";
import LoadComponent from "../../../../../../utilities/LoadComponent/LoadComponent";
import { DataContext } from "../../../../../../../context/DataContext";
import EvidenceTable from "./DataListComponent/EvidenceTable/EvidenceTable";
import { useUserProfile } from "../../../../../../../context/UserProfile";
import { useAtom } from "jotai";
import { documentsUploadTaskAtom } from "../../../../../../../atoms";
import { abortTask } from "../../../../../../../utils/workers";
import CatalogTable from "../../../../../../../pages/CatalogTable";
import { toast } from "../../../../../../utilities/Toast";

export default function DataList(props) {
  const legendRef = useRef(null);
  const [expandTags, setExpandTags] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [showAllEvidence, setShowAllEvidence] = useState(false);
  const [currentItemKey, setCurrentItemKey] = useState("");
  const [sortedChunkMappings, setSortedChunkMappings] = useState({});
  const [documentsUploadTask, setDocumentsUploadTask] = useAtom(
    documentsUploadTaskAtom,
  );

  const {
    currentDataGroup,
    processingFile,
    currentProcessCount,
    currentTotalProcessCount,
    availableTags,
    handleEvidenceButtonClick,
    evidenceData,
    setEvidenceData,
    isEvidenceModalOpen,
    setIsEvidenceModalOpen,
  } = useContext(DataContext);
  const { username } = useUserProfile(); // Get username from user profile

  useEffect(() => {
    const sortedChunkKeys = Object.keys(
      currentDataGroup[currentItemKey]?.chunks || {},
    ).sort((a, b) => {
      const aStart = parseInt(a.split("_")[0], 10);
      const bStart = parseInt(b.split("_")[0], 10);
      return aStart - bStart;
    });

    const chunkMappings = {};
    sortedChunkKeys.forEach((chunkKey, index) => {
      chunkMappings[chunkKey] = `Chunk ${index + 1}`;
    });

    setSortedChunkMappings(chunkMappings);
  }, [currentDataGroup, currentItemKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!legendRef.current?.contains(event.target)) {
        setExpandTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    processingFile,
    expandedRowKey,
    currentProcessCount,
    currentTotalProcessCount,
  ]);

  if (props.answerLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="overflow-hidden flex w-full h-full flex-col rounded-b-md">
      {/* <ProgressBar /> */}
      <div className="rounded-t-md w-full justify-end flex p-3 bg-white transition-all duration-200">
        <p className="SelectionCountText">
          {documentsUploadTask && (
            <button
              onClick={async () => {
                const creds = (await Auth.currentAuthenticatedUser()).username;
                await abortTask(documentsUploadTask, creds);
                setDocumentsUploadTask(null);

                toast.info({
                  title: "Aborting document upload",
                });
              }}
              className="text-sm bg-red-400 px-4 py-2 rounded-md"
            >
              Abort Upload
            </button>
          )}
        </p>
      </div>

      <div className="flex w-full h-full overflow-hidden">
        <CatalogTable
          onEvidenceClick={handleEvidenceButtonClick}
          availableTags={availableTags}
        />
      </div>
      <EvidenceTable
        showAllEvidence={showAllEvidence}
        setShowAllEvidence={setShowAllEvidence}
        evidenceData={evidenceData}
        setEvidenceData={setEvidenceData}
        currentItemKey={currentItemKey}
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        sortedChunkMappings={sortedChunkMappings}
        username={username} // Pass username as a prop
      />
    </div>
  );
}
