import "./ChatInterface.css";
import DocumentList from "./ChatInterfaceComponents/DocumentList/DocumentList";
import ChatBox from "./ChatInterfaceComponents/ChatBox/ChatBox";
import ChatHistory from "./ChatInterfaceComponents/ChatHistory/ChatHistory";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { ChatContext } from "../../../../../context/ChatContext";

function ChatInterface() {
  const { getUsecaseHistory } = useContext(ChatContext);
  const { usecaseSelected } = useContext(DataContext);

  const [documentsSelected, setDocumentsSelected] = useState(
    Object.keys(usecaseSelected.usecase_catalog)
  );

  useEffect(() => {
    getUsecaseHistory();
  }, []);

  return (
    <div className="flex gap-4 w-full h-full">
      <DocumentList
        documentsSelected={documentsSelected}
        setDocumentsSelected={setDocumentsSelected}
      />
      <ChatBox documentsSelected={documentsSelected} usecaseSelected={usecaseSelected} />
      <ChatHistory />
    </div>
  );
}

export default ChatInterface;
