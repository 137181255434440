import React from "react";
import "./DataFilterModal.css";

export default function DataFilterModal({ isOpen, onClose, title, children }) {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black z-[999999999] bg-opacity-60 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="w-[95vw] h-[90vh] flex flex-col bg-white p-4 rounded-md shadow-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="DataFilterModalContent">{children}</div>
      </div>
    </div>
  );
}
