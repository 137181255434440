import {
  useMemo,
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { DataContext } from "../../../../../context/DataContext";
import { useUserProfile } from "../../../../../context/UserProfile";
import {
  getRules,
  uploadRules,
} from "../../../../utilities/functions/apiCalls";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../../../utilities/Toast";

const ALLCONSTANT = "All";
export default function AddTaggingRules() {
  const {
    usedCatalog,
    setAvailableRules,
    availableTags,
    catalogSummary,
    setView,
    showConnectData,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();

  const [selectedTag, setSelectedTag] = useState("");
  const [bulkTag, setBulkTag] = useState("");
  const [tableData, setTableData] = useState([]);
  const [allRules, setAllRules] = useState({});
  const [filter, setFilter] = useState("");
  const [conditionFilter, setConditionFilter] = useState("");
  const [skipRules, setSkipRules] = useState(false);
  const [applyWithoutRules, setApplyWithoutRules] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const dropdownRef = useRef(null);

  const disabledClass = selectedTag ? "" : "opacity-20 pointer-events-none";

  const tagAvailableValues = useMemo(() => {
    const tags = Object.values(availableTags).reduce(
      (acc, value) => ({
        ...acc,
        ...Object.entries(value.tagger_params?.tag_dict || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.availableValues || [],
          }),
          {}
        ),
      }),
      {}
    );
    return tags;
  }, [availableTags]);

  useEffect(() => {
    getRules(usedCatalog, "tag_rule_set")
      .then((fetchedRules) => {
        const filteredRules = Object.keys(fetchedRules)
          .filter((tag) => Object.keys(tagAvailableValues).includes(tag))
          .reduce((acc, tag) => {
            acc[tag] = fetchedRules[tag];
            return acc;
          }, {});
        setAllRules(filteredRules);
      })
      .catch((error) => {
        console.error("Failed to fetch rules:", error);
      });
  }, [usedCatalog, availableTags]);

  useEffect(() => {
    let tableInit = [];

    if (selectedTag === ALLCONSTANT) {
      Object.entries(allRules).forEach(([tag, rules]) => {
        rules.forEach((rule) => {
          if (rule.tags && rule.tags.length > 0) {
            tableInit.push({
              Available_Values: rule.conditions[0].value,
              tags: rule.tags,
              tagName: tag,
            });
          }
        });
      });
    } else if (selectedTag) {
      const specificRules = allRules[selectedTag] || [];
      const initialValues = tagAvailableValues[selectedTag] || [];
      tableInit =
        specificRules.length > 0
          ? specificRules.map((rule) => ({
              Available_Values: rule.conditions[0].value,
              tags: rule.tags || [],
              tagName: rule.conditions[0].tag,
            }))
          : initialValues.map((value) => ({
              Available_Values: value,
              tags: [],
              tagName: selectedTag,
            }));
    }

    const filteredTableInit = tableInit.filter((row) => {
      return (
        typeof row.Available_Values === "string" &&
        row.Available_Values.toLowerCase().includes(
          conditionFilter.toLowerCase()
        )
      );
    });

    setTableData(filteredTableInit);
  }, [selectedTag, allRules, conditionFilter, tagAvailableValues]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSkip = () => {
    if (selectedTag) {
      saveRules();
    }
    setView("tagSelection");
  };

  const addTagToRow = (tagName, rowIndex) => {
    const updatedData = [...tableData];
    if (!updatedData[rowIndex].tags.includes(tagName)) {
      updatedData[rowIndex].tags.push(tagName);
      setTableData(updatedData);
    }
  };

  const removeTagFromRow = (tagName, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].tags = updatedData[rowIndex].tags.filter(
      (tag) => tag !== tagName
    );
    setTableData(updatedData);
  };

  const saveRules = async () => {
    if (selectedTag && selectedTag !== ALLCONSTANT) {
      const newRulesForSelectedTag = tableData.map((row) => ({
        conditions: [{ tag: selectedTag, value: row["Available_Values"] }],
        tags: row.tags,
      }));

      const rulesToSave = {
        ...allRules,
        [selectedTag]: newRulesForSelectedTag,
      };
      await uploadRules(rulesToSave, usedCatalog, "tag_rule_set")
        .then(() => {
          setAllRules(rulesToSave);
          setAvailableRules(rulesToSave[selectedTag]);
          toast.success({
            title: "Success",
            description: "Rules successfully saved!",
          });
        })
        .catch((error) => {
          console.error("Failed to upload rules:", error);
          toast.error({
            title: "Error",
            description: "Rules saving failed. Please try again.",
          });
        });
    }
  };

  const addTagToAll = () => {
    const updatedData = tableData.map((row) => ({
      ...row,
      tags: [...new Set([...row.tags, bulkTag])],
    }));
    setTableData(updatedData);
  };

  const deleteTagFromAll = () => {
    const updatedData = tableData.map((row) => ({
      ...row,
      tags: row.tags.filter((tag) => tag !== bulkTag),
    }));
    setTableData(updatedData);
  };

  const toggleDropdown = (rowIndex) => {
    setEditingRowIndex(rowIndex);
    setDropdownOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col w-full h-full relative p-5  z-40 bg-white rounded">
      <h1 className="text-lg font-semibold text-grey">
        Tagging Rules Configuration
      </h1>
      {skipRules ? (
        <p className="text-sm text-gray-600">
          All tags will be applied by default. No specific rules are set.
        </p>
      ) : (
        <div className="flex flex-col mt-2 ">
          <div className={`flex flex-col w-full justify-between`}>
            <input
              type="text"
              placeholder="Search conditions..."
              onChange={(e) => setConditionFilter(e.target.value)}
              className={`mb-2 px-3 py-2 border rounded shadow-sm ${disabledClass}`}
            />

            <div className="flex flex-row w-full justify-between mt-4">
              <div className="w-full flex flex-col">
                <Listbox value={selectedTag} onChange={setSelectedTag}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium text-gray-700">
                        <p className="text-sm text-gray-600">
                          {!selectedTag
                            ? "Select a tag to see or define its rules."
                            : "Configure rules for the selected tag."}
                        </p>
                      </Listbox.Label>
                      <div className="relative mt-1 w-[30%]">
                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="block truncate">
                            {selectedTag || "Select a tag"}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-auto">
                            <div className="p-2">
                              <input
                                type="text"
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="Search tags..."
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </div>
                            <Listbox.Option
                              key={ALLCONSTANT}
                              className={({ active }) =>
                                `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                  active
                                    ? "text-white bg-indigo-600"
                                    : "text-gray-900"
                                }`
                              }
                              value={ALLCONSTANT}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    All
                                  </span>
                                  {selected && (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active
                                          ? "text-white"
                                          : "text-indigo-600"
                                      }`}
                                    />
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                            {Object.keys({
                              ...availableTags.llm.tagger_params.tag_dict,
                              ...availableTags.sensitivity.tagger_params
                                .tag_dict,
                            }).map((tag) => (
                              <Listbox.Option
                                key={tag}
                                className={({ active }) =>
                                  `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900"
                                  }`
                                }
                                value={tag}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {tag}
                                      {allRules[tag] &&
                                        allRules[tag].length > 0 && (
                                          <CheckIcon className="inline-block w-4 h-4 ml-2 text-green-500" />
                                        )}
                                    </span>
                                    {selected && (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active
                                            ? "text-white"
                                            : "text-indigo-600"
                                        }`}
                                      />
                                    )}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
          </div>
        </div>
      )}
      {permissions.rules.canEdit && (
        <div className="mt-4 w-full h-full mb-20 overflow-auto ">
          <table className={` w-full mt-2 text-left ${disabledClass}`}>
            <thead className="bg-gray-200">
              <tr>
                <th className="px-2 py-2">Tag</th>
                <th className="px-2 py-2">Condition</th>
                <th className="px-2 py-2">Tags triggered</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex} className="border-b">
                  <td className="px-4 py-2 break min-w-[5vw]">
                    {row["tagName"]}
                  </td>
                  <td className="px-4 py-2 break min-w-[5vw]">
                    {row["Available_Values"]}
                  </td>
                  <td className="px-4 py-2">
                    <div className="flex items-center ">
                      <button
                        className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => toggleDropdown(rowIndex)}
                      >
                        +
                      </button>

                      {row.tags.map((tag, tagIndex) => (
                        <span
                          key={tagIndex}
                          className="m-1 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                        >
                          {tag}
                          <button
                            className="ml-2"
                            onClick={() => removeTagFromRow(tag, rowIndex)}
                          >
                            ×
                          </button>
                        </span>
                      ))}
                    </div>
                    {dropdownOpen && editingRowIndex === rowIndex && (
                      <div
                        ref={dropdownRef}
                        className="absolute mt-2 w-[30vw] bg-white shadow-lg border rounded-md z-10 overflow-auto h-[30vh] "
                      >
                        {Object.keys({
                          ...availableTags.llm.tagger_params.tag_dict,
                          ...availableTags.sensitivity.tagger_params.tag_dict,
                        })
                          .filter((tag) => tag !== selectedTag)
                          .map((tag) => (
                            <div
                              key={tag}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => addTagToRow(tag, rowIndex)}
                            >
                              {tag}
                            </div>
                          ))}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!showConnectData && permissions.rules.canEdit && (
        <div className="flex flex-row justify-start">
          <button
            className="text-lg mt-4 bg-primary hover:bg-grey text-white font-bold py-2 px-4 rounded "
            onClick={() => saveRules(selectedTag)}
            disabled={selectedTag === ALLCONSTANT}
            style={{ opacity: selectedTag === ALLCONSTANT ? 0.5 : 1 }}
            title={"Please select a tag to save rules."}
          >
            Save Rules
          </button>
        </div>
      )}
      {showConnectData && (
        <div className="w-full flex items-start justify-start absolute left-5 bottom-10 ">
          <button
            className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded z-50"
            onClick={() => setView("s3List")}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        </div>
      )}
      <div className="w-full flex items-end justify-end absolute bottom-10 right-5 gap-3">
        {showConnectData && (
          <>
            <button
              className={`mt-4  bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded z-50 ${disabledClass}`}
              onClick={() => saveRules(selectedTag)}
            >
              Save Rules
            </button>
            <button
              onClick={handleSkip}
              className="px-4 py-2 bg-primary text-white hover:bg-grey  rounded z-50"
            >
              {selectedTag ? "Continue" : "Skip"}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
