import { useState, useContext, useMemo } from "react";
import { ProfileComponent } from "./PreferencesComponents/ProfileComponent";
import { CatalogComponent } from "./PreferencesComponents/CatalogComponent";
import { TokenUsageComponent } from "./PreferencesComponents/TokenUsageComponent";
import { DataContext } from "../../../../../context/DataContext";
import { savePreferences } from "../../../../utilities/functions/apiCalls";
import { toast } from "../../../../utilities/Toast";
import { TeamTokenUsageComponent } from "./PreferencesComponents/TeamTokenUsage";
import { useUserProfile } from "../../../../../context/UserProfile";

type DataContextType = {
  preferences: any;
  setPreferences: React.Dispatch<React.SetStateAction<any>>;
};
type Tab =
  | "profile"
  | "hidden_tags"
  | "webapp_profile"
  | "catalogs"
  | "token_usage"
  | "team_usage";

type Props = {
  onSubmit?: (prefernces: any) => void;
  visibleTabs?: Tab[];
};
export default function UserPreferences({ onSubmit, visibleTabs }: Props) {
  const { permissions, profile, webapp_profile, hidden_tags } =
    useUserProfile();
  const { preferences, setPreferences } =
    useContext<DataContextType>(DataContext);
  const [activeTab, setActiveTab] = useState("profile");
  const [isSaving, setIsSaving] = useState(false);
  const [currentFormValues, setCurrentFormValues] = useState<any>(preferences);

  const availableTabs = useMemo(() => {
    const basicTabs: Tab[] = [
      "profile",
      "hidden_tags",
      "webapp_profile",
      "catalogs",
      "token_usage",
    ];

    if (permissions.teams.canView) {
      basicTabs.push("team_usage");
    }

    return basicTabs.filter((tab) => visibleTabs?.includes(tab) ?? true);
  }, [permissions, visibleTabs]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "profile":
      case "hidden_tags":
      case "webapp_profile":
        return (
          <ProfileComponent
            activeTab={activeTab}
            currentFormValues={currentFormValues}
            setCurrentFormValues={setCurrentFormValues}
          />
        );
      case "catalogs":
        return <CatalogComponent />;
      case "token_usage":
        return <TokenUsageComponent />;
      case "team_usage":
        return <TeamTokenUsageComponent />;
      default:
        return <div>Select a tab</div>;
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      onSubmit(currentFormValues);
      return;
    }

    setIsSaving(true);
    const updatedPreferences = JSON.parse(JSON.stringify(currentFormValues));
    try {
      await savePreferences(updatedPreferences);
      toast.success({
        title: "Preferences saved successfully.",
        description: "",
      });
      setPreferences(updatedPreferences);
    } catch (error) {
      toast.error({
        title: `Saving preferences failed: ${error}`,
        description: "",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col border-none rounded-lg p-4 mb-5 bg-gray-100 shadow-md w-[60vw] h-[calc(100vh-100px)]">
      <div className="bg-white p-3 rounded-lg shadow-md flex flex-col flex-1 overflow-hidden">
        <div className="flex mb-5 space-x-1 overflow-auto whitespace-nowrap">
          {availableTabs.map((tab) => (
            <button
              key={tab}
              className={`px-5 py-2 text-sm ${activeTab === tab ? "bg-primary" : "bg-gray-200"} hover:bg-primary focus:outline-none`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
            </button>
          ))}
        </div>
        <div className="flex-1 overflow-auto">{renderActiveTab()}</div>
      </div>
      {activeTab !== "catalogs" && activeTab !== "token_usage" && (
        <button
          className={`mt-4 px-5 py-2 ${isSaving ? "bg-gray-400" : "bg-green-600 hover:bg-green-700"} text-white rounded-lg shadow-lg disabled:bg-gray-400`}
          onClick={handleSubmit}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save Preferences"}
        </button>
      )}
    </div>
  );
}
