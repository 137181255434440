import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import "./DropDownMenu.css";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../../../constants/labelConfig";
import { Popover } from "react-tiny-popover";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropDownMenu({
  label,
  categoryKey,
  options = [],
  onFilterChange,
  selectedOptions,
  catalogSummary,
  handleResetFilter,
  showScreen,
}) {
  const hasSelection = selectedOptions && selectedOptions.size > 0;
  const [searchTearm, setSearchTearm] = useState("");

  return (
    <Menu
      as="div"
      className="whitespace-nowrap shadow rounded-md overflow-hidden"
    >
      <Popover
        isOpen={true}
        positions={["bottom", "left"]} // preferred positions by priority
        padding={10}
        align="start"
        containerClassName="z-[999999999]"
        content={
          <Menu.Items className="bg-white max-w-[300px] text-sm overflow-y-auto max-h-[400px] border rounded-md">
            <input
              value={searchTearm}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSearchTearm(e.target.value);
              }}
              placeholder="search"
              className="w-full h-12 bg-slate-200 p-4 outline-none"
            />
            <div>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      "border-b w-full text-left p-4",
                      active && "bg-secondary text-white"
                    )}
                    onClick={() => {
                      setSearchTearm("");
                      onFilterChange(categoryKey, new Set(options));
                    }}
                  >
                    All
                  </button>
                )}
              </Menu.Item>

              {options
                .filter((option) => {
                  return (
                    option &&
                    option.toString().toLowerCase().includes(searchTearm.toLowerCase())
                  );
                })
                .sort()
                .map((option) => (
                  <Menu.Item key={option}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "border-b w-full text-left p-4",
                          active ||
                            (selectedOptions && selectedOptions.has(option))
                            ? "bg-secondary text-white"
                            : "text-gray-700"
                        )}
                        onClick={() => {
                          setSearchTearm("");
                          const newSelectedOptions = new Set(
                            selectedOptions || []
                          );
                          if (newSelectedOptions.has(option)) {
                            newSelectedOptions.delete(option);
                          } else {
                            newSelectedOptions.add(option);
                          }
                          onFilterChange(categoryKey, newSelectedOptions);
                        }}
                      >
                        {option}
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        }
      >
        <Menu.Button className="px-4 py-2 rounded-md items-center bg-primary text-white flex gap-4">
          <span className="text-white text-sm">
            {label} {selectedOptions?.size ? `(${selectedOptions?.size})` : ""}
          </span>
          {hasSelection && (
            <div>
              <XIcon
                className={`w-4 h-4 text-white font-medium rounded-full bg- ${
                  LABELS_TO_EXCLUDE_FOR_SEARCH.includes(categoryKey)
                    ? "opacity-50"
                    : "opacity-100"
                }`}
                onClick={(e) =>
                  !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(categoryKey) &&
                  handleResetFilter(e, categoryKey)
                }
              />
            </div>
          )}
        </Menu.Button>
      </Popover>
    </Menu>
  );
}
