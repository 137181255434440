export default function TabComponent({
  activeTab,
  setActiveTab,
  handleFileChange,
  fileInputRef = null,
}) {
  const tabs = [
    {
      label: "Manually define tag",
      action: (index) => handleManuallyDefineTag(index),
    },

    {
      label: "Test & refine tags",
      action: (index) => handleTestRefineTags(index),
    },
    {
      label: "Auto-generate tags",
      action: (index) => handleAutoGenerateTags(index),
    },
    {
      label: "Create tag rules",
      action: (index) => handleCreateTagRules(index),
    },
    {
      label: "Import tags",
      action: (index) => handleImportTags(index),
    },
  ];

  const handleManuallyDefineTag = (index) => {
    setActiveTab(index);
  };

  const handleAutoGenerateTags = (index) => {
    setActiveTab(index);
  };

  const handleTestRefineTags = (index) => {
    setActiveTab(index);
  };

  const handleCreateTagRules = (index) => {
    setActiveTab(index);
  };

  const handleImportTags = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="w-full flex bg-gray-200 flex-row justify-between">
      {tabs.map((tab, index) => {
        if (index === 3) {
          return (
            <div
              key={index}
              className={`w-full px-4 py-2 cursor-pointer ${
                index !== tabs.length - 1 ? "border-r" : ""
              } border-gray-400 ${
                activeTab === index
                  ? "text-bold bg-deasieTurquoise text-white text-lg"
                  : "bg-gray-200 text-gray-600 text-lg"
              }`}
              onClick={() => {
                tab.action(index);
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".csv"
                onChange={handleFileChange}
              />
              {tab.label}
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className={`w-full px-4 py-2 cursor-pointer ${
                index !== tabs.length - 1 ? "border-r" : ""
              } border-gray-400 ${
                activeTab === index
                  ? "text-bold bg-deasieTurquoise text-white text-lg"
                  : "bg-gray-200 text-gray-600 text-lg"
              }`}
              onClick={() => {
                tab.action(index);
              }}
            >
              {tab.label}
            </div>
          );
        }
      })}
    </div>
  );
}
