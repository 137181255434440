import * as types from "./types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import Auth from "../auth/AuthProvider";
import {
  fetchTeamTokenUsage,
  fetchUserTokenUsage,
} from "../components/utilities/functions/apiCalls";
import {
  getAuthHeaders,
  sendRequest,
} from "../components/utilities/functions/api";
import { backendBaseUrl } from "../utils/config";
import { ENDPOINTS } from "./endpoints";
import { AvailableCatalogsResponse } from "./types";
import { useUserProfile } from "../context/UserProfile";

export const useCatalogNames = () =>
  useQuery({
    queryKey: ["catalogNames"],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest({ username }, "/api/catalog", "GET")
        .then((res) => res.json())
        .then((res: { data: AvailableCatalogsResponse }) => {
          const catalogNames = res.data.catalogs;
          return catalogNames;
        });
    },
  });

export const useCreateCatalogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (catalogName: string) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username, catalog_name: catalogName },
        ENDPOINTS["add_new_catalog"],
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["catalogNames"] });
    },
  });
};

export type TokenModelCost = {
  name: string;
  cost: number;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
};
export type TokenEntry = {
  date: string;
  model_costs: TokenModelCost[];
};
export type TokenUsageTracking = {
  token_list: {
    token_list: TokenEntry[];
    total_cost: number;
    total_token_cost: number;
  };
};
export const useTokenUsage = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  return useQuery({
    queryKey: [
      "tokenUsage",
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
    ],
    queryFn: async () => {
      return (await fetchUserTokenUsage(
        format(startDate, "yyy-MM-dd"),
        format(endDate, "yyy-MM-dd"),
      )) as Promise<TokenUsageTracking>;
    },
  });
};

export const useTeamTokenUsage = (startDate: Date, endDate: Date) => {
  return useQuery({
    queryKey: [
      "teamTokenUsage",
      format(startDate, "yyy-MM-dd"),
      format(endDate, "yyy-MM-dd"),
    ],
    queryFn: async () => {
      return (await fetchTeamTokenUsage(
        format(startDate, "yyy-MM-dd"),
        format(endDate, "yyy-MM-dd"),
      )) as Promise<{
        users_usage: {
          username: string;
          email: string;
          token_usage: TokenUsageTracking;
        }[];
      }>;
    },
  });
};

export const useFileUplaodMutation = (
  {
    storage,
  }: {
    storage: {
      type: "s3" | "azureblob";
      name: string;
      base_path: string;
      credentials:
        | {
            access_key_id: string;
            secret_access_key: string;
          }
        | {
            client_id: string;
            client_secret: string;
            tenant_id: string;
          };
    };
  },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  },
) => {
  return useMutation({
    mutationFn: async ({ files }: { files: File[] }) => {
      const formData = new FormData();
      formData.set("storage_type", storage.type);
      formData.set("storage_name", storage.name);
      formData.set("credentials", JSON.stringify(storage.credentials));
      formData.set("base_path", storage.base_path);

      files.forEach((file) => {
        formData.append("files", file);
      });
      const response = await fetch(
        `${backendBaseUrl}${ENDPOINTS["upload_files"]}`,
        {
          method: "POST",
          body: formData,
          headers: {
            ...(await getAuthHeaders()),
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onError,
  });
};

export type EvidenceReportEntry = {
  timestamp: string;
  username: string;
  tagname: string;
  email: string;
  catalogname: string;
  catalog_team: string | null;
  is_valid: boolean;
  filename: string;
};

export const useEvidenceReportsMutation = () => {
  const { catalog_team } = useUserProfile();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      reportEntries: Omit<
        EvidenceReportEntry,
        "catalog_team" | "username" | "timestamp" | "email"
      >[],
    ) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      const requests = reportEntries.map((entry) => ({
        ...entry,
        username,
        catalog_team,
        timestamp: new Date().toISOString(),
      }));
      return Promise.all(
        requests.map((request) =>
          sendRequest(request, ENDPOINTS["evidence_reports"]),
        ),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["evidenceReports"] });
    },
  });
};

export const useEvidenceReports = () => {
  return useQuery({
    queryKey: ["evidenceReports"],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username },
        ENDPOINTS["evidence_reports"],
        "GET",
      ).then((res) => res.json());
    },
  });
};

// TODO: Need API endpoint for this
// NOTE: unused
export const useCatalogEvidenceReports = (catalogName: string) => {
  return useQuery({
    queryKey: ["catalogEvidenceReports", catalogName],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username },
        `${ENDPOINTS["evidence_reports"]}${catalogName}/`,
        "GET",
      ).then((res) => res.json());
    },
  });
};

export const useUpateTeamPreferencesMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (preferences: types.TeamPreferencesUpdateRequest) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username, ...preferences },
        ENDPOINTS["team_preferences"],
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teamPreferences"] });
      onSuccess?.();
    },
  });
};
