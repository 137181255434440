import { WebPubSubClient } from "@azure/web-pubsub-client";
import Auth from "../auth/AuthProvider";
import { API, GraphQLSubscription } from "@aws-amplify/api";
import { useEffect, useMemo, useRef } from "react";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { onTableUpdate } from "../components/utilities/GraphQL/subscriptions";
import { FETCH_DATA_SUBSCRIPTION } from "../constants/fixedValues";
import { useUserProfile } from "../context/UserProfile";

export const useCatalogChangeSubscription = ({
  activeCatalog,
  onChange,
  onQuarantinedFileChange,
}: {
  activeCatalog: string;
  onChange(file_name: string, file_entry: any): void;
  onQuarantinedFileChange(file_name: string, file_entry: any): void;
}) => {
  const userProfile = useUserProfile();
  const subscriptionRefs = useRef({
    catalog: {
      unsubscribe: () => {},
    },
    quarantine: {
      unsubscribe: () => {},
    },
  });
  const catalogTableName = useMemo(() => {
    if (userProfile.catalog_team) {
      return `${userProfile.user_group}-${userProfile.catalog_team}-${activeCatalog}`;
    }

    return `${userProfile.username}-${activeCatalog}`;
  }, [
    activeCatalog,
    userProfile.catalog_team,
    userProfile.user_group,
    userProfile.username,
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchAuthUser = async () => {
      try {
        return (await Auth.currentAuthenticatedUser()).username;
      } catch (error) {
        console.error("Authentication error:", error);
      }
    };

    const subscribeToAWSAppSync = async () => {
      const user = await fetchAuthUser();
      const graphQlCall = await API.graphql<GraphQLSubscription<any>>({
        query: onTableUpdate,
        variables: {
          username_catalog_name: catalogTableName,
        },
      });
      // @ts-ignore
      const catalogSubscription = graphQlCall.subscribe({
        next: (eventData: any) => {
          if (!isSubscribed) return;
          const file_name = eventData.value.data.onTableUpdate.file_name;
          const file_entry = JSON.parse(
            eventData.value.data.onTableUpdate.file_entry,
          );

          onChange(file_name, file_entry);
        },
      });

      const quarantineSubscription = API.graphql({
        query: onTableUpdate,
        variables: {
          username_catalog_name: `${user}-${userProfile.system.QUARANTINECATALOG}`,
        },
        // @ts-ignore
      }).subscribe({
        next: (eventData: any) => {
          if (!isSubscribed) return;
          const file_name = eventData.value.data.onTableUpdate.file_name;
          const file_entry = JSON.parse(
            eventData.value.data.onTableUpdate.file_entry,
          );

          onChange(file_name, file_entry);
        },
        error: (error: unknown) => console.error(error),
      });

      subscriptionRefs.current.catalog = catalogSubscription;
      subscriptionRefs.current.quarantine = quarantineSubscription;
    };

    const subscribeToAzurePubSub = async () => {
      const user = await fetchAuthUser();
      const client = new WebPubSubClient({
        getClientAccessUrl: async () => {
          let value = await (
            await sendRequest(
              {
                [userProfile.system.API_USERNAME_KEYWORD]: user,
              },
              ENDPOINTS["pubsub_token"],
              "GET",
            )
          ).json();
          return value.url;
        },
      });

      await client.start();
      client.on("connected", () => {});

      await client.joinGroup(`${user}-${activeCatalog}`);
      await client.joinGroup(`${user}-${userProfile.system.QUARANTINECATALOG}`);

      client.on("group-message", (e: any) => {
        if (!isSubscribed) return;
        const eventData = JSON.parse(e.message.data);
        const username_catalog_name = eventData["username_catalog_name"];
        const file_name = eventData["file_name"];
        const file_entry = JSON.parse(eventData["file_entry"]);

        if (username_catalog_name === `${user}-${activeCatalog}`) {
          onChange(file_name, file_entry);
        } else if (
          username_catalog_name ===
          `${user}-${userProfile.system.QUARANTINECATALOG}`
        ) {
          onQuarantinedFileChange(file_name, file_entry);
        }
      });

      subscriptionRefs.current.catalog = { unsubscribe: () => client.stop() };
      subscriptionRefs.current.quarantine = { unsubscribe: () => {} };
    };

    const fetchDataSubscription = async () => {
      if (FETCH_DATA_SUBSCRIPTION === "AWSAPPSYNC") {
        await subscribeToAWSAppSync();
      } else if (FETCH_DATA_SUBSCRIPTION === "AZUREPUBSUB") {
        await subscribeToAzurePubSub();
      }
    };

    fetchDataSubscription();

    return () => {
      console.log("Unsubscribing from subscriptions");
      isSubscribed = false;
      Object.values(subscriptionRefs.current).forEach(
        // @ts-ignore
        (sub) => sub?.unsubscribe?.() || sub?.close?.(),
      );
      subscriptionRefs.current = {
        catalog: {
          unsubscribe: () => {},
        },
        quarantine: {
          unsubscribe: () => {},
        },
      };
    };
  }, [
    activeCatalog,
    catalogTableName,
    onChange,
    onQuarantinedFileChange,
    userProfile.system.API_USERNAME_KEYWORD,
    userProfile.system.QUARANTINECATALOG,
  ]);
};
