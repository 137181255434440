import React, { useState, useContext } from "react";
import { List, AutoSizer } from "react-virtualized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./SimplifiedDataList.css";
import { fetchDocumentContent } from "../functions/apiCalls";
import Modal from "../Modal/Modal";
import { DataContext } from "../../../context/DataContext";

import { PlusIcon } from "@heroicons/react/solid";

export default function SimpleDataList(props) {
  const [selectedName, setSelectedName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { preferences } = useContext(DataContext);

  const getDocumentContent = async (itemKey) => {
    setSelectedName(itemKey);
    setIsModalOpen(true);
    setModalContent("...Loading");

    const documentContent = await fetchDocumentContent(
      props.currentDataGroup[itemKey].file_directory + "/" + itemKey,
      preferences.webapp_profile.DATA_STORES
    );

    setModalContent(documentContent);
  };

  const rowRenderer = ({ key, index, style }) => {
    const itemKey = Object.keys(props.currentDataGroup)[index];

    return (
      <div key={key} style={style} className="SimpleDataItemContainer">
        <div className="SimpleDataItemContent">
          <header className="SimpleTagsDataName">{itemKey}</header>
        </div>
        <button className="bg-primary px-4 py-2 rounded-md text-white">
          {props.handleDelete ? (
            <button onClick={() => props.handleDelete(itemKey)}>Delete</button>
          ) : (
            <FontAwesomeIcon
              icon={faEye}
              className=""
              onClick={() => getDocumentContent(itemKey)}
            />
          )}
        </button>
        {props.handleAddSingleData && (
          <button
            onClick={() => props.handleAddSingleData(itemKey)}
            className="add-dataset-button ml-4"
          >
            <PlusIcon className="icon" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="h-[65vh] bg-slate-200 rounded-b-md flex flex-col px-4 pb-4">
      <div className="SimpleDataListContainer">
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width - 1}
              height={height - 1}
              rowCount={
                props.currentDataGroup
                  ? Object.keys(props.currentDataGroup).length
                  : 0
              }
              rowHeight={70}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedName}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
