import React, { useContext, useEffect, useState, useRef } from "react";
import "./ChatHistory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ChatContext } from "../../../../../../../context/ChatContext";

function ChatHistory() {
  const {
    chats,
    currentChatId,
    setCurrentChatId,
    createChat,
    deleteChat,
    renameChat,
    setMessages,
    isHistoryLoading,
  } = useContext(ChatContext);

  const [newChatName, setNewChatName] = useState("");
  const [visibleOptionsChatId, setVisibleOptionsChatId] = useState(null);
  const [isDeleting, setIsDeleting] = useState({});
  const chatItemRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (visibleOptionsChatId !== null) {
        const currentRef = chatItemRefs.current[visibleOptionsChatId];
        if (currentRef && !currentRef.contains(event.target)) {
          setVisibleOptionsChatId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleOptionsChatId]);

  const handleSelectChat = (chatId) => {
    if (chatId === currentChatId) {
      return;
    }

    if (chats && chatId && chats[chatId]) {
      const chatMessages = chats[chatId].messages.map((msg, index) => ({
        id: msg.id,
        text: msg.content,
        type: msg.role,
      }));
      setMessages(chatMessages);
    } else {
      setMessages([]);
    }
    setCurrentChatId(chatId);
  };

  const handleCreateChat = async () => {
    if (newChatName.trim()) {
      const response = await createChat(newChatName.trim());
      setNewChatName("");
      handleSelectChat(response.chat_id);
    }
  };

  const handleDeleteChat = async (chatId) => {
    setIsDeleting((prev) => ({ ...prev, [chatId]: true }));
    await deleteChat(chatId);
    setIsDeleting((prev) => ({ ...prev, [chatId]: false }));
    setVisibleOptionsChatId(null);
  };

  const handleRenameChat = (chatId) => {
    const newName = prompt("Name of chat: ");
    if (newName) {
      renameChat(chatId, newName);
    }
  };

  const toggleOptionsVisibility = (chatId) => {
    if (!isDeleting[chatId]) {
      setVisibleOptionsChatId((prevId) => (prevId === chatId ? null : chatId));
    }
  };

  return (
    <div className="chatHistorySidebar">
      <div className="chatHistoryHeader">
        <header>
          <h1>Chat History</h1>
        </header>
      </div>
      <div className="chatHistoryContent w-80">
        {isHistoryLoading && Object.keys(chats).length === 0 ? (
          <div className="loadingChats">Loading chats...</div>
        ) : Object.keys(chats).length > 0 ? (
          Object.entries(chats).map(([chatId, { chat_name, messages }]) => (
            <div
              key={chatId}
              ref={(el) => (chatItemRefs.current[chatId] = el)}
              className={`chatItem ${currentChatId === chatId ? "active" : ""}`}
              onClick={() => handleSelectChat(chatId)}
            >
              <div style={{ flex: 1 }}>{chat_name}</div>
              <button
                className="optionsButton"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOptionsVisibility(chatId);
                }}
                disabled={isDeleting[chatId]}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
              {visibleOptionsChatId === chatId && (
                <div className="optionsDropdown">
                  <button onClick={() => handleDeleteChat(chatId)} disabled={isDeleting[chatId]}>
                    {isDeleting[chatId] ? <FontAwesomeIcon icon={faSpinner} spin /> : "Delete"}
                  </button>
                  <button onClick={() => handleRenameChat(chatId)} disabled={isDeleting[chatId]}>Rename</button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="noChats">No chat history.</div>
        )}
      </div>
      <div className="createChatContainer px-4 py-4 ">
        <input
          type="text"
          className="createChatInput"
          value={newChatName}
          onChange={(e) => setNewChatName(e.target.value)}
          placeholder="New chat name"
        />
        <button
          className="text-sm z-50 w-full text-white bg-primary hover:bg-secondary px-4 py-4 rounded-b shrink-0 grow-0"
          onClick={handleCreateChat}
        >
          Create
        </button>
      </div>
    </div>
  );
}

export default ChatHistory;
