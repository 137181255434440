import React, { useContext, useState, useRef, useEffect } from "react";
import "./DocumentList.css";
import Modal from "../../../../../../utilities/Modal/Modal";
import { fetchDocumentContent } from "../../../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../../../context/DataContext";
import { ChatContext } from "../../../../../../../context/ChatContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { DownloadIcon } from "../../../DataCatalog/DataCatalogComponents/DataList/DataListComponent/EvidenceTable/EvidenceTable";
import { isImage } from "../../../../../../utilities/functions/utils";
import { UsecaseContext } from "../../../../../../../context/UsecaseContext";
export const TextGenerationUsecase = "Text Generation";

export default function DocumentList(props) {
  const [modalContent, setModalContent] = useState("");
  const [selectedName, setSelectedName] = useState("");

  const {
    relevantDocuments,
    setMessages,
    setRelevantDocuments,
    evidences,
    setCurrentChatId,
    setChats,
  } = useContext(ChatContext);

  const {
    setShowScreen,
    catalogFiles,
    isModalOpen,
    setModalOpen,
    usecaseSelected,
    preferences,
  } = useContext(DataContext);

  const [documentEntries, setDocumentEntries] = useState([]);
  const [imageString, setImageString] = useState("");
  const [contextSearch, setContextSearch] = useState("");

  const filteredContextFiles =
    usecaseSelected && usecaseSelected.usecase_catalog
      ? Object.keys(usecaseSelected.usecase_catalog)
          .filter((file) =>
            file.toLowerCase().includes(contextSearch.toLowerCase())
          )
          .sort()
      : [];

  const getDocumentContent = async (entry) => {
    let path = "";
    const componentName = entry.name;

    const fileName = Object.keys(catalogFiles).find((key) =>
      componentName.includes(key.replace(/\.\w+$/, ""))
    );

    if (isImage(entry.name)) {
      const folderName = entry.name.includes("table")
        ? "tables"
        : "infographics";
      path = `vector-store/${usecaseSelected.id}_storage/${fileName}/${folderName}/${componentName}`;
    } else {
      path = catalogFiles[fileName].file_directory + "/" + componentName;
    }
    setModalOpen(true);
    setModalContent("Loading...");

    let documentText = await fetchDocumentContent(
      path,
      preferences.webapp_profile.DATA_STORES,
      entry.chunkRange,
      false,
      catalogFiles[fileName].data_store_name ? catalogFiles[fileName].data_store_name : catalogFiles[fileName].storage_type
    );

    if (!isImage(entry.name)) {
      documentText = documentText.replaceAll(
        entry.evidence,
        `<span class="evidence">${entry.evidence}<\/span>`
      );
    } else {
      setImageString(documentText);
    }

    setModalOpen(true);
    setModalContent(documentText);
    setSelectedName(entry.name);
  };

  const resetChat = () => {
    setCurrentChatId(null);
    setMessages([]);
    setRelevantDocuments([]);
    setChats({});
    setShowScreen("usecase");
  };

  useEffect(() => {
    const entries = transformDocuments();
    setDocumentEntries(entries);
  }, [relevantDocuments]);

  const handleDownload = () => {
    const headers = "ID,Name,Evidence,Chunk Range\n";

    const csvRows = documentEntries
      .map((entry) => {
        const chunkRange = entry.chunkRange.join("-");
        return `"${entry.id}","${entry.name}","${entry.evidence.replace(
          /"/g,
          '""'
        )}","${chunkRange}"`;
      })
      .join("\n");

    const csvContent = `${headers}${csvRows}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "document-entries.csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const transformDocuments = () => {
    return Object.entries(relevantDocuments).flatMap(
      ([docName, { evidences, chunk_indices }]) => {
        if (docName) {
          // TODD: Add better check for image file
          const isImageFile = isImage(docName);
          if (isImageFile) {
            return [
              {
                id: `${docName}`,
                name: docName,
                evidence: "",
                chunkRange: [],
              },
            ];
          }

          return evidences.map((evidence, index) => {
            const chunkStartIndex = index * 2;
            let chunkRange = [];
            if (chunk_indices) {
              chunkRange = chunk_indices.slice(
                chunkStartIndex,
                chunkStartIndex + 2
              );
            }

            return {
              id: `${docName}_Evidence_${index}`,
              name: docName,
              evidence,
              chunkRange,
            };
          });
        }
      }
    );
  };

  const handleContextFilesChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    const currentSelection = props.documentsSelected;

    const newSelection = selectedOptions
      .filter((option) => !currentSelection.includes(option))
      .concat(
        currentSelection?.filter((option) => !selectedOptions.includes(option))
      );

    props.setDocumentsSelected(newSelection);
  };

  const selectAllDocuments = () => {
    props.setDocumentsSelected(filteredContextFiles);
  };

  // Function to deselect all documents
  const deselectAllDocuments = () => {
    props.setDocumentsSelected([]);
  };

  return (
    <div className="DocumentList">
      <div className="HeaderContainer">
        <header>Documents used to answer your questions</header>
      </div>
      <div className="text-generation-controls">
        <header>Context</header>
        <p className="description">
          Select documents that provide contextual knowledge for the text that
          you would like to generate.
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={contextSearch}
          onChange={(e) => setContextSearch(e.target.value)}
          className="search-input"
        />

        <div className="flex flex-col mt-2">
          <div className="flex justify-between items-center mb-2">
            <div>
              <button
                onClick={selectAllDocuments}
                className="text-sm px-4 py-2 text-white bg-primary hover:bg-secondar font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
              >
                Select All
              </button>
              <button
                onClick={deselectAllDocuments}
                className="text-sm ml-2 px-4 py-2 text-white bg-red-500 hover:bg-red-700 font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
              >
                Deselect All
              </button>
            </div>
            <span className="text-sm font-medium text-gray-600">
              {props.documentsSelected.length} selected
            </span>
          </div>
        </div>
        <select
          value={props.documentsSelected}
          onChange={handleContextFilesChange}
          className="select-box"
          multiple={true}
        >
          {filteredContextFiles &&
            filteredContextFiles.sort().map((file) => (
              <option key={file} value={file} className="select-option">
                {file}
              </option>
            ))}
        </select>
      </div>
      {documentEntries && Object.keys(documentEntries).length !== 0 ? (
        <div className="PlaceholderContainer">
          <p>
            Ask a question and Deasie will show you which documents are
            relevant.
          </p>
        </div>
      ) : (
        <ul
          style={{
            zIndex: isModalOpen ? -1 : 1,
            padding: "10px",
            overflow: "auto",
            paddingBottom: "2vh",
            flex: 0.6,
            display: documentEntries.length === 0 ? "none" : "block",
          }}
          className="h-full"
        >
          {documentEntries.map((entry, index) => (
            <li
              key={index}
              className="DocumentContainer"
              onClick={() => getDocumentContent(entry)}
            >
              <p className="RelevantDocumentText">{entry.id}</p>
              {evidences.has(entry.name) | isImage(entry.name) && (
                <span className="evidence-exist">
                  <FontAwesomeIcon icon={faCheck} />
                  Found evidence
                </span>
              )}
              {entry.name &&
                !evidences.has(entry.name) &&
                !isImage(entry.name) && (
                  <span className="evidence-missing">
                    <FontAwesomeIcon icon={faXmark} />
                    Could not find evidence
                  </span>
                )}
              {
                <div>
                  {!isImage(entry.name)
                    ? `Evidence: ${entry.evidence}`
                    : "Please view the image to see the evidence"}
                </div>
              }
            </li>
          ))}
        </ul>
      )}

      <button
        className="text-sm z-50 w-full text-white bg-primary hover:bg-secondary px-4 py-4 rounded-b shrink-0 grow-0"
        onClick={() => resetChat()}
        style={{ position: "absolute", bottom: "0px" }}
      >
        Back to Deasie Catalog
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title={selectedName}
        imageString={imageString}
      >
        <span dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>
    </div>
  );
}
