import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFileIconMapper } from "./hooks";

export const FileCheckbox = ({ fileName, isSelected, onClick }) => {
  const { getIconFor } = useFileIconMapper();
  const fileTypeClass = `file-type-${fileName.split(".").pop()}`;

  return (
    <div
      className={`flex items-center gap-2 ${fileTypeClass} ${
        isSelected ? "selected" : ""
      }`}
    >
      <input
        className="ml-3.5"
        type="checkbox"
        id={`checkbox-file-${fileName}`}
        checked={isSelected}
        onChange={() => onClick(fileName)}
      />
      <FontAwesomeIcon
        icon={getIconFor(fileName)}
        className="file-icon text-xl"
      />
      <label className="cursor-pointer" htmlFor={`checkbox-file-${fileName}`}>
        {fileName}
      </label>
    </div>
  );
};
