import React, { useContext, useEffect, useRef } from "react";
import Message from "../Message/Message";
import "./ChatBox.css";
import { COLOURS } from "../../../../../../../assets/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faRedo,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../../../../context/DataContext";
import { ChatContext } from "../../../../../../../context/ChatContext";

function ChatBox(props) {
  const { isModalOpen } = useContext(DataContext);
  const {
    messages,
    setRelevantDocuments,
    isChatLoading,
    handleSendMessage,
    newMessage,
    setNewMessage,
    currentChatId,
    clearChat,
    deleteMessage,
  } = useContext(ChatContext);

  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleReset = () => {
    if (currentChatId) {
      clearChat(currentChatId);
    }

    setRelevantDocuments([]);
  };

  const handleEnterSendMessage = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(props.documentsSelected);
    }
  };

  const handleDeleteMessage = (selectedMessageId) => {
    deleteMessage(currentChatId, selectedMessageId);
  };

  const noMessages = messages.length === 0;

  return (
    <div className="rounded-md flex flex-col justify-between w-full bg-white">
      <div className="ChatBoxHeader">DeasieMind - Usecase: {props.usecaseSelected.name}</div>
      <div className="ChatBoxContainer" ref={chatBoxRef}>
        {noMessages && !isChatLoading ? (
          <div className="Placeholder">
            <p>Ask something and Deasie will help you</p>
          </div>
        ) : (
          messages.map((message) => (
            <Message
              key={message.id}
              message={message}
              handleDeleteMessage={handleDeleteMessage}
            />
          ))
        )}
        {isChatLoading && (
          <div className="Message assistant">
            <div className="LoadingContainer">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="LoadingSpinner"
              />
            </div>
          </div>
        )}
      </div>
      <div
        className="flex items-center px-4 py-2 bg-slate-200"
        style={{ zIndex: isModalOpen ? -1 : 1 }}
      >
        <textarea
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleEnterSendMessage}
          className="ChatBoxInput outline-none h-full"
          disabled={isChatLoading}
        />
        <button
          onClick={handleReset}
          className="ResetChatButton"
          disabled={isChatLoading}
        >
          <FontAwesomeIcon icon={faRedo} />
        </button>
        <button
          onClick={(e) => handleSendMessage(props.documentsSelected)}
          className="SendMessageButton"
          style={{ backgroundColor: COLOURS["SubText"] }}
          disabled={isChatLoading}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
}

export default ChatBox;
